import { handleActions } from 'redux-actions';

import { SET_ORDER } from '../actions/order';

export const INITIAL_STATE: any = {}

export default handleActions({
    [SET_ORDER]: (state: any, action: any) => {
        return { ...action.payload };
    }
}, INITIAL_STATE);
