import Logo from "./svg/copec_logo.svg";
import Delivery from "./svg/delivery.svg";
import Close from "./svg/close.svg";
import Marker from "./svg/maps_icon.svg";
import MarkerHouse from "./svg/house.svg";
import MarkerHouseBlue from "./svg/pin_blue.svg";
import MarkerHouseRed from "./svg/pin_red.svg";
import MarkerHouseYellow from "./svg/pin_yellow.svg";
import Flag from "./svg/flag.svg";

const Images = {
    Logo: {
        src: Logo,
        alt: 'logomarca'
    },
    Delivery: {
        src: Delivery,
        alt: 'icon'
    },
    Close: {
        src: Close,
        alt: 'icon'
    },
    Marker: {
        src: Marker,
        alt: 'icon'
    },
    MarkerHouse: {
        src: MarkerHouse,
        alt: 'icon'
    },
    MarkerHouseBlue: {
        src: MarkerHouseBlue,
        alt: 'icon'
    },
    MarkerHouseRed: {
        src: MarkerHouseRed,
        alt: 'icon'
    },
    MarkerHouseYellow: {
        src: MarkerHouseYellow,
        alt: 'icon'
    },
    Flag: {
        src: Flag,
        alt: 'icon'
    },
}
export default Images;