export const compare = (itemsOrder: any, itemsComplement: any) => {
  const formatedItemsOrder: any = itemsOrder.map((item: any) => {
    const name: any = item.info?.name?.toLowerCase() === 'envío' ? 'Despacho' : item.info?.name;
    return {
      name: name,
      price: item.info?.unit_price,
      quantity_to_buy: item.info?.quantity_to_buy,
      new_quantity_to_buy: item.info?.quantity_to_buy,
      canceled: false,
      item_id: item.PK,
      new_item_id: item.PK,
      amount_discount: item.info?.amount_discount || 0,
      PK: item.PK,
      SK: item.SK,
      item_type: name === 'Despacho' ? 100 : 0,
    };
  });

  const formatedItemsComplement: any = itemsComplement.map((item: any) => {
    let canceled: any = true;
    if (item.name?.toLowerCase() === 'envío' || item.name?.toLowerCase() === 'envío') canceled = false;
    if (item.quantity_to_buy > 0) canceled = false;

    const name: any = item.name?.toLowerCase() === 'envío' ? 'Despacho' : item.name;
    const payload: any = {
      PK: item.PK,
      SK: item.SK,
      name: name,
      quantity_to_buy: item.quantity_to_buy,
      new_quantity_to_buy: item.quantity_to_buy,
      price: item.price,
      canceled: canceled,
      item_id: item.PK,
      new_item_id: item.PK,
      amount_discount: item.amount_discount || 0,
      item_type: name === 'Despacho' ? 100 : 0,
    };

    return payload;
  });

  let itemsWithoutChanges: any = [];
  let itemsWithChanges: any = [];
  let newItems: any = [];

  formatedItemsOrder.forEach((item: any) => {
    const withoutChanges: any = formatedItemsComplement.filter((el: any) => el.PK === item.PK && el.SK === item.SK && el.quantity_to_buy === item.quantity_to_buy);
    itemsWithoutChanges = [...itemsWithoutChanges, ...withoutChanges];

    const withChanges: any = formatedItemsComplement
      .filter((el: any) => el.PK === item.PK && el.SK === item.SK && el.quantity_to_buy !== item.quantity_to_buy)
      .map((el: any) => {
        el.quantity_to_buy = item.quantity_to_buy;
        return el;
      });

    itemsWithChanges = [...itemsWithChanges, ...withChanges];
  });

  formatedItemsComplement.forEach((item: any) => {
    const isExist: any = formatedItemsOrder.find((el: any) => el.PK === item.PK && el.SK === item.SK);

    if (!isExist) {
      newItems.push({ ...item });
    }
  });

  itemsWithoutChanges.sort((a: any, b: any) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  itemsWithoutChanges.sort((a: any, b: any) => {
    if (a.item_type > b.item_type) return 1;
    if (a.item_type < b.item_type) return -1;
    return 0;
  });

  itemsWithChanges.sort((a: any, b: any) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  newItems.sort((a: any, b: any) => {
    if (a.name > b.name) return 1;
    if (a.name < b.name) return -1;
    return 0;
  });

  let total: any = 0;
  let amount_discount: any = 0;

  total = itemsWithoutChanges.reduce((acc: any, current: any) => {
    if (current.quantity_to_buy > 0) {
      acc += current.price * current.quantity_to_buy;
    }
    return acc;
  }, 0);

  total += itemsWithChanges.reduce((acc: any, current: any) => {
    if (current.new_quantity_to_buy > 0) {
      acc += current.price * current.new_quantity_to_buy;
    }
    return acc;
  }, 0);

  total += newItems.reduce((acc: any, current: any) => {
    if (current.quantity_to_buy > 0) {
      acc += current.price * current.quantity_to_buy;
    }
    return acc;
  }, 0);

  amount_discount = itemsWithoutChanges.reduce((acc: any, current: any) => {
    if (current.quantity_to_buy > 0) {
      acc += current.amount_discount * current.quantity_to_buy;
    }
    return acc;
  }, 0);

  amount_discount += itemsWithChanges.reduce((acc: any, current: any) => {
    if (current.new_quantity_to_buy > 0) {
      acc += current.amount_discount * current.new_quantity_to_buy;
    }
    return acc;
  }, 0);

  amount_discount += newItems.reduce((acc: any, current: any) => {
    if (current.quantity_to_buy > 0) {
      acc += current.amount_discount * current.quantity_to_buy;
    }
    return acc;
  }, 0);

  return { itemsWithoutChanges, itemsWithChanges, newItems, total, amount_discount };
};

export const payment_types: any = [
  {
    label: 'Presencial',
    value: 1,
  },
  {
    label: 'WebPay Credito',
    value: 3,
  },
  {
    label: 'WebPay Debito',
    value: 2,
  },
];
