import { handleActions } from 'redux-actions';

import { ADD_CONFIGS, CLEAR_ROUTE } from '../actions/router';

export const INITIAL_STATE: any = {
  list: [],
  waypoints: [],
  configs: {
    start_point: null,
    end_point: null,
  },
};

export default handleActions(
  {
    [CLEAR_ROUTE]: (state: any, action: any) => {
      return {
        list: [],
        waypoints: [],
        configs: {
          start_point: null,
          end_point: null,
        },
      };
    },
    [ADD_CONFIGS]: (state: any, action: any) => {
      const { payload } = action;
      return { ...state, configs: { ...payload } };
    },
  },
  INITIAL_STATE
);
