import { Tooltip, Whisper } from 'rsuite';

const WhisperComponent = (props: any) => {
  const { text, children } = props;
  return (
    <Whisper trigger="hover" placement="auto" controlId={`control-id-auto`} speaker={<Tooltip style={{zIndex: '10000'}}>{text || ''}</Tooltip>}>
      <div style={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{children}</div>
    </Whisper>
  );
};

export default WhisperComponent;
