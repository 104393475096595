import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import Orders from '../../services/order.service';
import { useDispatch } from 'react-redux';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import { set_order } from '../../redux/actions/order';
import { Badge } from 'rsuite';
import ConfirmationModal from '../ConfirmationModal';
import { ReactComponent as CarIcon } from '../../assets/images/svg/car-front.svg';
import MapsCard from '../../pages/OrderMap/MapsCard';

const OrderCard = (props: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, states, updateOrders } = props;
  const [state, setState] = useState<any>({
    modal: {
      routeOn: false,
      delivered: false,
      details: false,
    },
    status: '',
    bgColor: '',
    confirmation: false,
    confirmationStatus: '',
    statusView: '',
    modalView: '',
    order: {},
  });

  useEffect(() => {
    const bgColors: any = {
      same: 'border-warning',
      after: 'border-primary',
      before: 'border-danger',
    };

    const badge: any = {
      same: {
        color: 'yellow',
        content: 'Entregar hoy',
      },
      after: {
        color: 'blue',
        content: 'Programado',
      },
      before: {
        color: 'red',
        content: 'Atrasado',
      },
    };
    if (data.status !== 'delivered' && data.status !== 'canceled') {
      data.bgColor = bgColors[data.delivery_status];
      data.badge = badge[data.delivery_status];
    }

    if (data.status === 'delivered') {
      data.bgColor = 'border-success';
      data.badge = {
        color: 'green',
        content: 'Entregado',
      };
    }

    if (data.status === 'canceled') {
      data.bgColor = 'border-dark';
      data.badge = {
        color: 'violet',
        content: 'Anulado',
      };
    }

    if (data.delivery_status === 'after') {
      if (data.status === 'new') {
        data.bgColor = 'border-dark';
        data.badge = {
          color: 'violet',
          content: 'Nuevo',
        };
      }
    }
  }, [data]);

  const _handleStatus = async (status: any, modal: any) => {
    dispatch(LOADING_ON());
    try {
      const params = {
        order_id: data.PK,
        PK: data.PK,
        SK: data.SK,
        status: status,
      };
      await Orders.changeStatus(params);
      await updateOrders(modal);
      setState({ ...state, confirmation: false });
      switch (status) {
        case 'on_route':
          toast.success('Despacho iniciado con éxito.');
          break;
        case 'delivered':
          toast.success('Pedido entregado con éxito.');
          break;
        default:
          toast.success('Pedido programado con éxito.');
          break;
      }
    } catch (e: any) {
      dispatch(LOADING_OFF());
      toast.error('No fue posible cambiar el estado');
    }
  };

  const _setOrder = () => {
    dispatch(set_order(data));
    navigate(`/order/${data.PK}?PK=${data.PK}&SK=${data.SK}`);
  };

  const _handlePreChangeStatus = async () => {
    await _handleStatus(state.statusView, state.modalView);
  };

  const _openConfirmationModal = (status: any) => {
    setState({ ...state, ...status, confirmation: true });
  };

  const _handleCloseModal = () => {
    setState({ ...state, modal: { ...state.modal, details: false } });
  };

  const _getDetailsOrder = async (item: any) => {
    try {
      dispatch(LOADING_ON());
      const orderDetail: any = await Orders.detailsOrden({ PK: item.PK, SK: item.SK });
      let total: any = orderDetail.data.amount + orderDetail.data.amount_shipping;

      if (orderDetail.data?.complement?.length > 0) {
        total -= orderDetail.data.complement[0].amount_diff;
        orderDetail.data.is_complemented = true;
      }

      const [, order_status] = orderDetail.data.GS1PK?.split('-');
      orderDetail.data.status = order_status?.toLowerCase();
      orderDetail.data.total_without_discount = total;

      const [paymentPK, _] = item.PK.split('-')
      const paymentDetails: any = await Orders.paymentDetails(paymentPK);

      orderDetail.data.paymentData = paymentDetails.data;

      orderDetail.data?.items.sort((a: any, b: any) => {
        if (a.info?.type_item > b.info?.type_item) return 1;
        if (a.info?.type_item < b.info?.type_item) return -1;
        return 0;
      });

      setState({
        ...state,
        modal: { ...state.modal, details: true },
        order: { ...orderDetail.data, delivery_day: item.delivery_day },
      });

      dispatch(LOADING_OFF());
    } catch (e: any) {
      toast.error('No se puedo recuperar los datos de este pedido');
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className={`container-fluid bg-white border-default rounded-10 px-2 py-3 shadow  ${data.bgColor}`} style={{ borderWidth: '2px' }}>
      <ConfirmationModal type="warning" title="Cambio de estado" open={state.confirmation} handleConfirm={_handlePreChangeStatus} handleClose={() => setState({ ...state, confirmation: false })}>
        ¿Estás seguro que quieres cambiar el estado a <b>{state.confirmationStatus}</b>?
      </ConfirmationModal>

      <ConfirmationModal handleClose={_handleCloseModal} handleConfirm={_handleCloseModal} hideFooter open={state.modal.details} title="">
        <MapsCard state={state} hideDetailsButton />
      </ConfirmationModal>

      <div className="row px-2 py-3 position-relative">
        <div className="position-absolute" style={{ top: '-10px', right: '7px', width: 'fit-content' }}>
          <Badge {...data.badge} className="bold px-2" />
        </div>
        <div className="col-10">
          <div className="row mt-2">
            <div className="col-12 color-blue bold-300">Pedido N: #{data.PK}</div>

            <div className="col-12 size-08 mt-2">
              <i className="bi bi-calendar me-2" />
              Fecha Entrega: {data.delivery_day}
            </div>

            <div className="col-12 size-08 mt-2">
              <i className="bi bi-telephone me-2" />
              Teléfono: <a href={`tel:${data?.user?.phone_plain || data?.user?.phone_number_plain || ''}`}> {data?.user?.phone_formated || data?.user?.phone_number_formated || ''} </a>
            </div>

            <div className="col-12 size-08 mt-2 d-flex">
              <i className="bi bi-geo-alt me-2" />
              <span>
                Dirección:
                <a href={`https://waze.com/ul?ll=${data?.info?.direction?.latitude},${data?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank">
                  {' '}
                  {data?.info?.direction?._string}
                </a>
              </span>
            </div>

            <div className="col-12 size-08 mt-2 capitalize">
              <i className="bi bi-person me-2" />
              Nombre: {data?.user?.name?.toLowerCase()} {data?.user?.family_name?.toLowerCase()}
            </div>

            <div className="col-12 size-08 mt-2">
              <i className="bi bi-truck me-2" />
              {data.amount_to_pay < 0 ? (
                <>
                  Monto a reembolsar ( callcenter ): ${(data.amount_to_pay * -1)?.toLocaleString('es-CL')}
                </>
              ) : (
                <>
                  Monto a cobrar: ${data.amount_to_pay?.toLocaleString('es-CL')}
                </>
              )}

            </div>

            <div className="col-12 size-08 mt-2">
              <i className="bi bi-truck me-2" />
              Estado: {states?.find((s: any) => s.value?.toLowerCase() === data.status?.toLowerCase())?.label}
            </div>

            {data.quantity_kerosene_items ? (
              <div className="col-12 size-08 mt-2">
                <i className="bi bi-droplet me-2" />
                Bidones: {data.quantity_kerosene_items}
              </div>
            ) : null}

            <div className="col-12 size-08 mt-2">
              <i className="bi bi-cart-plus me-2" />
              Otros Productos: {data.has_other_items ? 'Si' : 'No'}
            </div>

            {data?.info?.vehicle ? (
              <div className="col-12 size-08 mt-2">
                <CarIcon className="me-2" style={{ width: '13px' }} />
                Patente: <span style={{ textTransform: 'uppercase' }}>{data.info?.vehicle?.plate}</span>
              </div>
            ) : null}

            {data?.user?.house_type && data.user?.house_type === 2 ? (
              <>
                <div className="col-12 size-08 mt-2">
                  <i className="bi bi-key me-2" />
                  Llaves en conserjería: <span style={{ textTransform: 'uppercase' }}>{data.user?.keys ? 'Si' : 'No'}</span>
                </div>
                <div className="col-12 size-08 mt-2">
                  <i className="bi bi-house-door me-2" />
                  Estacionamiento: <span style={{ textTransform: 'uppercase' }}>{data.user?.parking}</span>
                </div>
              </>
            ) : null}
          </div>
        </div>
        <div className="col-2 color-white d-flex align-items-center justify-content-center position-relative">
          <div className="position-absolute" style={{ top: '7px' }}>
            <button className="btn rounded-10 px-2 py-1" onClick={() => _getDetailsOrder(data)}>
              <i className="bi bi-info-circle bold color-blue" style={{ fontSize: '20px' }} />
            </button>
          </div>
          <button className="btn-default rounded-10 px-2 py-1" onClick={_setOrder}>
            <i className="bi bi-arrow-right bold" />
          </button>
        </div>
      </div>

      <div className="row">
        {data.status === 'new' && (
          <div className="col-12 color-white">
            <button
              className="btn btn-default bold-300 w-100 rounded-10 py-2"
              onClick={() => _openConfirmationModal({ confirmationStatus: 'Programado', statusView: 'programmed', modalView: 'programmed' })}
            >
              Programar
            </button>
          </div>
        )}

        {(data.status === 'programmed' || data.status === 'rescheduled') && (
          <div className="col-12 color-white">
            <button className="btn btn-default bold-300 w-100 rounded-10 py-2" onClick={() => _openConfirmationModal({ confirmationStatus: 'En Ruta', statusView: 'on_route', modalView: 'routeOn' })}>
              Poner en ruta
            </button>
          </div>
        )}

        {data.status === 'on_route' && (
          <div className="col-12 color-white">
            <button
              className="btn btn-success bold-300 w-100 rounded-10 py-2"
              onClick={() => _openConfirmationModal({ confirmationStatus: 'Entregado', statusView: 'delivered', modalView: 'delivered' })}
            >
              Entregar
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default OrderCard;
