import { handleActions } from 'redux-actions';

import { SING_IN, SING_OUT } from '../actions/session';

export const INITIAL_STATE: any = {
    isLogged: false,
    profile: {},
    tokens: {}
}

export default handleActions({
    [SING_IN]: (state: any, action: any) => {
        return { ...state, ...action.payload, isLogged: true };
    },
    [SING_OUT]: (state: any, action: any) => {
        return { ...INITIAL_STATE };
    }
}, INITIAL_STATE);
