export const CARSIZES: any = [
  {
    id: 1,
    label: 'Pequeño',
  },
  {
    id: 2,
    label: 'Mediano',
  },
  {
    id: 3,
    label: 'Grande',
  },
  {
    id: 4,
    label: 'Extra Grande',
  },
];
