import { handleActions } from 'redux-actions';

import { SET_STATUS } from '../actions/select_status';

export const INITIAL_STATE: any = []

export default handleActions({
    [SET_STATUS]: (state: any, action: any) => {
        return [...action.payload];
    }
}, INITIAL_STATE);
