import Images from '../../assets/images'
import './style.scss';

const ModalComponent = (props: any) => {

  const { children, handleClose, open } = props
  return (
    <div className={`modal-component justify-content-center align-items-center ${open ? 'd-flex' : 'd-none'}`}>
      <div className="modal-component__container">
        <div className="modal-component__container__header">
          <div className="d-flex justify-content-end pt-3 pb-1 px-3">
            <button className="btn" onClick={handleClose}>
              <img {...Images.Close} />
            </button>
          </div>
        </div>
        <div className="modal-component__container__content">
          {children}
        </div>
      </div>
    </div>
  )
}

export default ModalComponent