import { useState } from 'react';
import QuantitySelect from '../QuantitySelect';
import { FaPen, FaTrash } from 'react-icons/fa';
import ConfirmationModal from '../ConfirmationModal';
import { SelectPicker } from 'rsuite';

const ProductCard = (props: any) => {
  const { data, _handleMinusQuantity, _handlePlusQuantity, _handleChangeService, _removeItem, is_related_product, disabled } = props;
  const [state, setState] = useState({
    update: false,
  });
  return (
    <div className="row px-2 py-4 align-items-center justify-content-end border-bottom mx-0">
      <div className="col-12 color-black bold-300">{data?.name?.toLowerCase() === 'envio' || data?.name?.toLowerCase() === 'envío' ? 'Despacho' : data?.name}</div>
      <div className="col-6 bold color-grey size-08">
        $ {data?.price?.toLocaleString('pt-BR')}
        <span className="mx-4">x</span>
        {data?.quantity}
      </div>

      <div className="col-6 color-grey d-flex justify-content-end align-items-center">
        {!disabled && (
          <>
            <button className="btn size-08 px-3 py-2" onClick={() => setState({ ...state, update: !state.update })}>
              <FaPen />
            </button>
            {!data._main && (
              <button className="btn size-08 px-3 py-2" onClick={() => _removeItem(data, is_related_product)} disabled={data.quantity === 0}>
                <FaTrash />
              </button>
            )}
          </>
        )}
      </div>

      {state.update && (
        <>
          {data.select_option ? (
            <div className="col-12 mt-4">
              <SelectPicker data={data.sub_services} cleanable={false} className="rs-copec-cleanable" classPrefix="copec" onChange={(e: any) => _handleChangeService(data, e)} placeholder="Selecciona un servicio" />
            </div>
          ) : (
            <div className="col-12 d-flex justify-content-center mt-4">
              <QuantitySelect quantity={data.quantity} handlePlus={() => _handlePlusQuantity(data, is_related_product)} handleMinus={() => _handleMinusQuantity(data, is_related_product)} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default ProductCard;
