import { handleActions } from 'redux-actions';

import { SET_FILTERS } from '../actions/filters';

export const INITIAL_STATE: any = {
  filter_order_status: 'programmed',
};

export default handleActions(
  {
    [SET_FILTERS]: (state: any, action: any) => {
      return { ...action.payload };
    },
  },
  INITIAL_STATE
);
