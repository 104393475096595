import { createAction } from 'redux-actions';


export const CLEAR_ROUTE: any = createAction('CLEAR_ROUTE');
export const ADD_ROUTE: any = createAction('ADD_ROUTE');
export const REMOVE_ROUTE: any = createAction('REMOVE_ROUTE');
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');


export const add_route = (payload: any) => (dispatch: any) => {
    dispatch(ADD_ROUTE(payload));
    dispatch(SAVE_CHANGES());
    return payload;
}

export const remove_route = (payload: any) => (dispatch: any) => {
    dispatch(REMOVE_ROUTE(payload));
    dispatch(SAVE_CHANGES());
    return payload;
}

export const clear_route = () => (dispatch: any) => {
    dispatch(CLEAR_ROUTE());
    dispatch(SAVE_CHANGES());
}