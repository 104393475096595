import { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { set_order } from '../../redux/actions/order';
import { payment_types } from '../../helpers/orders';
import { CARSIZES } from '../../helpers/car_sizes';

const MapsCard = (props: any) => {
  const { state, hideDetailsButton } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _setOrder = () => {
    const [, order_status] = state.order.GS1PK?.split('-');
    state.order.status = order_status?.toLowerCase();
    dispatch(set_order(state.order));
    navigate(`/order/${state.order.PK}?PK=${state.order.PK}&SK=${state.order.SK}`);
  };

  const AmountToCollect = (props: any) => {
    let amount: any = 0;
    if ((props.status === 'Programado' || props.status === 'Reagendado') && props.payment === 'Presencial') {
      amount = (props.state.total - props.state.amount_discount)?.toLocaleString('es-CL') ?? 0;
    }
    return <div className="col-12 text-end size-12 bold color-dark-blue mb-2">Monto a cobrar: $ {amount}</div>;
  };

  return (
    <div className="row ps-3" style={{ maxWidth: '100%' }}>
      <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Orden:</div>
      <div className="col-8 d-flex align-items-center size-09 mb-2">{state.order?.PK}</div>

      <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Nombre:</div>
      <div className="col-8 d-flex align-items-center size-09 capitalize mb-2">{state.order?.user?.name?.toLowerCase()}</div>

      <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Email:</div>
      <div className="col-8 d-flex align-items-center size-09 mb-2">{state.order?.user?.email}</div>

      <div className="col-4 d-flex align-items-center size-09 bold-300 mb-2">Teléfono:</div>
      <div className="col-8 d-flex align-items-center size-09 mb-2">
        <a href={`tel:${state.order?.user?.phone_plain || state.order?.user?.phone_number_plain || ''}`}> {state.order?.user?.phone_formated || state.order?.user?.phone_number_formated || ''} </a>
      </div>

      <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Dirección:</div>
      <div className="col-8 d-flex align-items-center size-09 mb-2">
        <a href={`https://waze.com/ul?ll=${state?.order?.info?.direction?.latitude},${state?.order?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank">
          {state.order?.info?.direction?._string}
        </a>
      </div>

      <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Entrega:</div>
      <div className="col-8 d-flex align-items-center size-09 mb-2">{state.order?.delivery_day}</div>

      <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Tipo de pago:</div>
      <div className="col-8 d-flex align-items-center size-09 mb-2">{payment_types.find((item: any) => item.value === state.order?.paymentData?.payment_type)?.label}</div>

      {state?.order?.info?.vehicle ? (
        <>
          <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Marca:</div>
          <div className="col-8 d-flex align-items-center size-09 mb-2">{state?.order?.info?.vehicle?.brand?.label}</div>
          <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Modelo:</div>
          <div className="col-8 d-flex align-items-center size-09 mb-2">{state?.order?.info?.vehicle?.model?.label}</div>
          <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Patente:</div>
          <div className="col-8 d-flex align-items-center size-09 mb-2" style={{ textTransform: 'uppercase' }}>
            {state?.order?.info?.vehicle?.plate}
          </div>
          <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Año:</div>
          <div className="col-8 d-flex align-items-center size-09 mb-2">{state?.order?.info?.vehicle?.year}</div>
          {state?.order?.info?.vehicle?.size ? (
            <>
              <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Tamaño:</div>
              <div className="col-8 d-flex align-items-center size-09 mb-2">{CARSIZES.find((item: any) => item.id === parseInt(state?.order?.info?.vehicle?.size))?.label}</div>
            </>
          ) : null}
        </>
      ) : null}

      {state.order.amount_off && state.order.amount_off > 0 ? (
        <>
          <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Subtotal: </div>
          <div className="col-8 d-flex align-items-center size-09 mb-2 bold">$ {(state.order?.total_without_discount)?.toLocaleString('es-CL')}</div>
          <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Descuento:</div>
          <div className="col-8 d-flex align-items-center size-09 mb-2 bold">$ {state.order?.amount_off?.toLocaleString('es-CL')}</div>
          <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Total:</div>
          <div className="col-8 d-flex align-items-center size-09 mb-2 bold">$ {(state.order?.total_without_discount - state.order?.amount_off)?.toLocaleString('es-CL')}</div>
        </>
      ) : (
        <>
          <div className="col-4 d-flex align-items-center bold-300 size-09 mb-2">Total: </div>
          <div className="col-8 d-flex align-items-center size-09 mb-2 bold">$ {(state.order?.total_without_discount)?.toLocaleString('es-CL')}</div>
        </>
      )}

      <div className="col-12 text-center bold mt-2">Ítems</div>

      {!state.order.is_complemented && state.order?.items?.map((item: any) => (
        <Fragment key={item.SK}>
          {item.info?.name?.toLowerCase() !== 'envío' && (
            <div className="border-bottom rounded-10 py-2 mb-3">
              <div className="size-08 bold-300">Nombre: {item.info?.name?.toLowerCase() === 'envío' ? 'Despacho' : item.info?.name}</div>
              <div className="size-08 bold-300">Cantidad: x {item.info?.quantity_to_buy} </div>
              <div className="size-08 bold-300">Precio: $ {(item.info?.unit_price * item.info?.quantity_to_buy).toLocaleString('es-CL')}</div>
            </div>
          )}
        </Fragment>
      ))}

      {state.order.is_complemented && state.order?.complement[0].info?.items?.map((item: any) => (
        <Fragment key={item.SK}>
          {item.info?.name?.toLowerCase() !== 'envío' && (
            <div className="border-bottom rounded-10 py-2 mb-3">
              <div className="size-08 bold-300">Nombre: {item?.name?.toLowerCase() === 'envío' ? 'Despacho' : item?.name}</div>
              <div className="size-08 bold-300">Cantidad: x {item?.quantity_to_buy} </div>
              <div className="size-08 bold-300">Precio: $ {(item?.price * item?.quantity_to_buy).toLocaleString('es-CL')}</div>
            </div>
          )}
        </Fragment>
      ))}

      {!state.order.is_complemented && state.order?.items?.map((item: any) => (
        <Fragment key={item.SK}>
          {item.info?.name?.toLowerCase() === 'envío' && (
            <div className="border-bottom rounded-10 py-2 mb-3">
              <div className="size-08 bold-300">Nombre: {item.info?.name?.toLowerCase() === 'envío' ? 'Despacho' : item.info?.name}</div>
              <div className="size-08 bold-300">Cantidad: x {item.info?.quantity_to_buy} </div>
              <div className="size-08 bold-300">Precio: $ {(item.info?.unit_price * item.info?.quantity_to_buy).toLocaleString('es-CL')}</div>
            </div>
          )}
        </Fragment>
      ))}

      {state.order.is_complemented && state.order?.complement[0].info?.items?.map((item: any) => (
        <Fragment key={item.SK}>
          {item.info?.name?.toLowerCase() === 'envío' && (
            <div className="border-bottom rounded-10 py-2 mb-3">
              <div className="size-08 bold-300">Nombre: {item?.name?.toLowerCase() === 'envío' ? 'Despacho' : item?.name}</div>
              <div className="size-08 bold-300">Cantidad: x {item?.quantity_to_buy} </div>
              <div className="size-08 bold-300">Precio: $ {(item?.unit_price * item?.quantity_to_buy).toLocaleString('es-CL')}</div>
            </div>
          )}
        </Fragment>
      ))}

      {hideDetailsButton ? null : (
        <div className="col-12 text-end color-white">
          <button className="rs-btn rs-btn-primary color-white" onClick={_setOrder}>
            Detalles
          </button>
        </div>
      )}
    </div>
  );
};
export default MapsCard;
