import { useState } from 'react'
import QuantitySelect from '../QuantitySelect'

const ProductCard = (props: any) => {
    const { data, _handleMinusQuantity, _handlePlusQuantity } = props

    return (
        <div className="row px-2 py-4 align-items-center border-bottom mx-0">
            <div className="col-5 color-black">
                {data?.name}
            </div>
            <div className="col-3 bold-300 color-black size-09">
                $ {data?.price.toLocaleString('pt-BR')}
            </div>
            <div className="col-4 color-black">
                <QuantitySelect quantity={data.quantity} handlePlus={() => _handlePlusQuantity(data.PK)} handleMinus={() => _handleMinusQuantity(data.PK)} />
            </div>
        </div>
    )

}
export default ProductCard