import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import InitiateDelivery from '../../components/InitiateDelivery';
import ModalComponent from '../../components/Modal';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import { set_order } from '../../redux/actions/order';
import Orders from '../../services/order.service';

const Order = (props: any) => {
  const [state, setState] = useState<any>({
    modal: {
      routeOn: false,
    },
    order: {},
    total: 0,
  });
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { select_status, order } = useSelector((state: any) => state);
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (location.search?.includes('update_order=ok')) {
      window.history.pushState({}, '', location.pathname);
      toast.success('Orden editada con éxito...');
      setTimeout(() => {
        toast.success('Status del pedido cambiado para entregado');
      }, 600);
    }
    _getOrderDetails();
  }, []);

  const _handleModal = (modal: any) => {
    setState({ ...state, modal: { ...state.modal, [modal]: !state.modal[modal] } });
  };

  const _getOrderDetails = async () => {
    dispatch(LOADING_ON());
    try {
      const response: any = await Orders.detailsOrden({ PK: searchParams.get('PK'), SK: searchParams.get('SK') });
      let total: any = response.data.amount + response.data.amount_shipping;

      let total_discount: any = response?.data?.amount_off

      total -= total_discount;

      if (response.data?.complement?.length > 0) {
        total -= response.data.complement[0].amount_diff;
      }

      const [, order_status] = response.data.GS1PK?.split('-');
      response.data.status = order_status?.toLowerCase();

      setState({ ...state, order: response.data, total });
    } catch (e: any) {
      toast.error('No fue posible encontrar la orden');
    }
    dispatch(LOADING_OFF());
  };

  const _handleStatus = async () => {
    dispatch(LOADING_ON());
    try {
      const params = {
        order_id: state.order.PK,
        PK: state.order.PK,
        SK: state.order.SK,
        status: 'on_route',
      };
      await Orders.changeStatus(params);
      order.status = 'on_route';
      dispatch(set_order(order));
      _handleModal('routeOn');
    } catch (e: any) {
      toast.error('No fue posible cambiar el estado');
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="contianer-fluid p-2">
      <ModalComponent open={state.modal.routeOn} handleClose={() => _handleModal('routeOn')}>
        <InitiateDelivery />
      </ModalComponent>

      <div className="row px-2 py-3 bg-white mx-1 rounded border-default shadow">
        <div className="col-12">
          <div className="row">
            <div className="col-12 color-blue bold-300">Pedido N: #{params.id}</div>

            <div className="col-12 size-08 mt-2">
              <i className="bi bi-truck me-2" />
              Estado: {select_status?.find((s: any) => s.value?.toLowerCase() === state.order.status?.toLowerCase())?.label}
            </div>

            <div className="col-12 size-08 mt-2 d-flex">
              <i className="bi bi-geo-alt me-2" />
              <span>
                Dirección:
                <a href={`https://waze.com/ul?ll=${state.order?.info?.direction?.latitude},${state.order?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank">
                  {' '}
                  {state.order?.info?.direction?._string}
                </a>
              </span>
            </div>

            <div className="col-12 size-08 mt-2">
              <i className="bi bi-cash me-2" />
              Total: CLP {state.total.toLocaleString('pt-BR')}
            </div>
          </div>
        </div>

        <div className="col-12 mt-3 ">
          <div className="row">
            <button className="btn size-09 col-6 ps-0 pe-1" onClick={() => navigate(`/order/details/${params.id}?PK=${searchParams.get('PK')}&SK=${searchParams.get('SK')}`)}>
              <div className="border text-center py-3 d-flex align-item-center justify-content-center flex-column" style={{ height: '100%' }}>
                <i className="bi bi-card-list" />
                <div>Detalles</div>
              </div>
            </button>

            <button
              className="btn size-09 col-6 ps-0 pe-1"
              onClick={() => navigate(`/order/update/${params.id}?PK=${searchParams.get('PK')}&SK=${searchParams.get('SK')}`)}
              disabled={order.status === 'delivered' || order.status === 'canceled' || state.order.complement?.length > 0}
            >
              <div
                className={`border text-center py-3 d-flex align-item-center justify-content-center flex-column ${
                  order.status === 'delivered' || order.status === 'canceled' || state.order.complement?.length > 0 ? 'bg-grey' : ''
                } `}
                style={{ height: '100%' }}
              >
                <i className="bi bi-pencil" />
                <div>Editar</div>
              </div>
            </button>
          </div>

          <div className="row">
            <button className="btn size-09 col-6 ps-0 pe-1" onClick={() => navigate(`/maps/${params.id}?PK=${searchParams.get('PK')}&SK=${searchParams.get('SK')}`)}>
              <div className="border text-center py-3 d-flex align-item-center justify-content-center flex-column" style={{ height: '100%' }}>
                <i className="bi bi-map me-2" />
                <div>Mapas</div>
              </div>
            </button>
            <button className="btn size-09 col-6 pe-0 ps-1" onClick={() => navigate(`/state/${params.id}?PK=${searchParams.get('PK')}&SK=${searchParams.get('SK')}`)}>
              <div className="border text-center py-3 d-flex align-item-center justify-content-center flex-column" style={{ height: '100%' }}>
                <i className="bi bi-star me-2" />
                <div>Estados</div>
              </div>
            </button>
          </div>

          <div className="row">
            {/* <button className="btn size-09 col-6 ps-0 pe-1" onClick={() => navigate(`/order/add-products/${params.id}`)} disabled={order.status === 'delivered' || order.status === 'canceled'} >
                            <div className={`border text-center py-3 d-flex align-item-center justify-content-center flex-column ${order.status === 'delivered' || order.status === 'canceled' ? 'bg-grey' : ''}`} style={{ height: '100%' }}>
                                <i className="bi bi-file-plus" />
                                <div>Complementar <br /> pedido</div>
                            </div>
                        </button> */}
            <button className="btn size-09 col-12 pe-0 ps-1" onClick={_handleStatus} disabled={order.status !== 'programmed' && order.status !== 'rescheduled'}>
              <div
                className={`border text-center py-3 d-flex align-item-center justify-content-center flex-column ${order.status !== 'programmed' && order.status !== 'rescheduled' ? 'bg-grey' : ''}`}
                style={{ height: '100%' }}
              >
                <i className="bi bi-truck me-2" />
                {(order?.status === 'programmed' || order?.status === 'rescheduled') && <div>Poner en ruta</div>}

                {order?.status === 'on_route' && <div>Pedido en ruta</div>}

                {order?.status === 'delivered' && <div>Pedido entregado</div>}

                {order?.status === 'canceled' && <div>Pedido anulado</div>}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Order;
