import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { CARSIZES } from '../../helpers/car_sizes';
import { compare } from '../../helpers/orders';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import { set_order } from '../../redux/actions/order';
import Orders from '../../services/order.service';
import ItemsComplement from './ItemsComplement';
import ItemsOrder from './ItemsOrder';

const OrderDetail = (props: any) => {
  const params = useParams();
  const { order, select_status } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(location.search);
  const [state, setState] = useState({
    payment: 'Cargando...',
    payment_types: [
      {
        label: 'Presencial',
        value: 1,
      },
      {
        label: 'WebPay Credito',
        value: 3,
      },
      {
        label: 'WebPay Debito',
        value: 2,
      },
    ],
    total: 0,
    original_amount: 0,
    diff: 0,
    orders: [],
    is_custom_order: false,
    amount_discount: 0,
    complementOrders: {},
  });

  useEffect(() => {
    _getPaymentDetails();
  }, []);

  const _getPaymentDetails = async () => {
    dispatch(LOADING_ON());
    try {
      const [payment_id] = order.PK?.split('-');
      const response = await Orders.paymentDetails(payment_id);
      let payment: any = state.payment_types.find((pt: any) => pt.value === Number(response.data.payment_type))?.label;

      //Simulate Web Pay
      // payment = 'Webpay Credito';

      const orderResponse: any = await Orders.detailsOrden({ PK: searchParams.get('PK'), SK: searchParams.get('SK') });
      const [, order_status] = orderResponse.data?.GS1PK?.split('-');
      orderResponse.data.status = order_status?.toLowerCase();

      const { items, complement } = orderResponse.data;
      let items_order: any = [];
      let total: any = 0;
      let amount_discount: any = 0;
      let is_custom_order: any = false;
      let original_amount: any = 0;
      let diff: any = 0;
      let responseCompare: any = {};

      if (complement?.length > 0) {
        const complementedOrder: any = complement[0];
        const itemsNewOrder: any = complementedOrder.info?.items;

        responseCompare = compare(items, itemsNewOrder);

        is_custom_order = true;
        diff = complementedOrder.amount_diff;
      } else {
        orderResponse.data.items.forEach((item: any) => {
          const name: any = item.info?.name?.toLowerCase() === 'envío' ? 'Despacho' : item.info?.name;
          items_order.push({
            name: name,
            price: item.info?.unit_price,
            quantity_to_buy: item.info?.quantity_to_buy,
            canceled: false,
            item_id: item.PK,
            new_item_id: item.PK,
            amount_discount: item.info?.amount_discount || 0,
            type_item: item.info?.type_item || 0,
          });
        });
      }

      amount_discount = orderResponse.data?.amount_off;


      if (is_custom_order) {
        total = responseCompare.total;
      } else {
        total = items_order.reduce((acc: any, current: any) => {
          if (!current.canceled) {
            acc += current.price * current.quantity_to_buy;
          }
          return acc;
        }, 0);
      }

      original_amount = items.reduce((acc: any, current: any) => {
        acc += current.info?.unit_price * current.info?.quantity_to_buy;
        return acc;
      }, 0);

      const shippingItem: any = items_order.find((item: any) => item.name?.toLowerCase() === 'despacho');
      const NotshippingItem: any = items_order.filter((item: any) => item.name?.toLowerCase() !== 'despacho');
      if (shippingItem) {
        NotshippingItem.push(shippingItem);
      }

      NotshippingItem.sort((a: any, b: any) => {
        if (a.type_item > b.type_item) return 1;
        if (a.type_item < b.type_item) return -1;
        return 0;
      });

      NotshippingItem.sort((a: any, b: any) => {
        if (a.canceled > b.canceled) return 1;
        if (a.canceled < b.canceled) return -1;
        return 0;
      });

      dispatch(set_order({ ...order, ...orderResponse.data }));

      setState({ ...state, payment, total, orders: NotshippingItem, is_custom_order, original_amount, diff, amount_discount, complementOrders: responseCompare });
    } catch (e: any) {
      toast.error(e.message);
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="container-fluid p-2">
      <div className="row bg-white border-default rounded-5 shadow mx-2 pb-5">
        <div className="col-12 color-blue bold-300 size-09 py-3 border-bottom">Pedido N: #{params.id}</div>

        <div className="col-12 size-09 mx-0 px-0 mt-3">{state.is_custom_order ? <ItemsComplement data={state.complementOrders} /> : <ItemsOrder data={state.orders} />}</div>

        <div className="col-12 size-09 mt-3">
          <div className="mb-2 size-08">Estado del pedido</div>
          <div className="bg-grey border rounded px-3 py-2 bold-300">{select_status?.find((s: any) => s.value?.toLowerCase() === order?.status)?.label}</div>
        </div>

        <div className="col-6 size-09 mt-3">
          <div className="mb-2 size-08">Tipo de pago</div>
          <div className="bg-grey border rounded px-3 py-2 bold-300">{state.payment}</div>
        </div>

        <div className="col-6 size-09 mt-3">
          <div className="mb-2 size-08">Total</div>
          <div className="bg-grey border rounded px-3 py-2 bold-300">CLP {state.total?.toLocaleString('pt-BR')}</div>
        </div>

        {state.payment?.toLowerCase() === 'presencial' ? (
          <>
            {state.amount_discount > 0 ? (
              <>
                <div className="col-12 size-09 mt-3">
                  <div className="mb-2 size-08">Descuento</div>
                  <div className="bg-grey border rounded px-3 py-2 bold-300">CLP {state?.amount_discount?.toLocaleString('pt-BR')}</div>
                </div>
                <div className="col-12 size-09 mt-3">
                  <div className="mb-2 size-08 bold">Monto a cobrar</div>
                  <div className="bg-grey border border-dark border-2 rounded px-3 py-2 bold-300">CLP {(state.total - (state?.amount_discount || 0))?.toLocaleString('pt-BR')}</div>
                </div>
              </>
            ) : (
              <div className="col-12 size-09 mt-3">
                <div className="mb-2 size-08 bold">Monto a cobrar</div>
                <div className="bg-grey border border-dark border-2 rounded px-3 py-2 bold-300">CLP {(state.total - (state?.amount_discount || 0))?.toLocaleString('pt-BR')}</div>
              </div>
            )}
          </>
        ) : (
          <>
            {state.amount_discount > 0 ? (
              <>
                <div className="col-12 size-09 mt-3">
                  <div className="mb-2 size-08">Descuento</div>
                  <div className="bg-grey border rounded px-3 py-2 bold-300">CLP {state?.amount_discount?.toLocaleString('pt-BR')}</div>
                </div>
                <div className="col-12 size-09 mt-3">
                  <div className="mb-2 size-08 bold">{Math.sign(state.diff) === 1 ? 'Monto a reembolsar (Call center)' : 'Monto a cobrar'}</div>
                  <div className="bg-grey border border-dark border-2 rounded px-3 py-2 bold">CLP {(Math.sign(state.diff) === -1 ? state.diff * -1 : state.diff)?.toLocaleString('pt-BR')}</div>
                </div>
              </>
            ) : (
              <>
                <div className="col-12 size-09 mt-3">
                  <div className="mb-2 size-08 bold">{Math.sign(state.diff) === 1 ? 'Monto a reembolsar (Call center)' : 'Monto a cobrar'}</div>
                  <div className="bg-grey border border-dark border-2 rounded px-3 py-2 bold">CLP {(Math.sign(state.diff) === -1 ? state.diff * -1 : state.diff)?.toLocaleString('pt-BR')}</div>
                </div>
              </>
            )}
          </>
        )}

        {order?.order_accumulated_points ? (
          <div className="col-12 size-09 mt-3">
            <div className="mb-2 size-08">Puntos Full</div>
            <div className="bg-grey border rounded px-3 py-2 bold-300">{order?.order_accumulated_points}</div>
          </div>
        ) : null}

        <div className="col-12 size-09 mt-3">
          <div className="mb-2 size-08">Dirección</div>
          <div className="bg-grey border rounded px-3 py-2 bold-300">{order?.info?.direction?._string}</div>
        </div>

        {order?.user?.house_type && order?.user?.house_type === 2 ? (
          <div className="col-12 size-09 mt-3">
            <div className="mb-2 size-08">Información adicional</div>
            <div className="bg-grey border rounded px-3 py-2 bold-300">
              Edificio, {order?.user?.parking?.toLowerCase().search('estac') !== -1 ? order?.user?.parking : `estacionamiento ${order?.user?.parking}`}
            </div>
          </div>
        ) : null}

        {order?.info?.direction?.apartment && (
          <div className="col-12 size-09 mt-3">
            <div className="mb-2 size-08">Casa/Departamento</div>
            <div className="bg-grey border rounded px-3 py-2 bold-300">{order?.info?.direction?.apartment}</div>
          </div>
        )}

        {order?.info?.direction?.observations && (
          <div className="col-12 size-09 mt-3">
            <div className="mb-2 size-08">Observaciones</div>
            <div className="bg-grey border rounded px-3 py-2 bold-300">{order?.info?.direction?.observations}</div>
          </div>
        )}

        <div className="col-12 size-09 mt-3">
          <div className="mb-2 size-08">Cliente</div>
          <div className="bg-grey border rounded px-3 py-2 bold-300">{`${order?.user?.name}`}</div>
        </div>
        <div className="col-12 size-09 mt-3">
          <div className="mb-2 size-08">Teléfono</div>
          <div className="bg-grey border rounded px-3 py-2 bold-300">{`${order?.user?.phone_formated || '.'} `}</div>
        </div>
        <div className="col-12 size-09 mt-3">
          <div className="mb-2 size-08">Email</div>
          <div className="bg-grey border rounded px-3 py-2 bold-300">{`${order?.user?.email}`}</div>
        </div>

        {order?.info?.vehicle ? (
          <>
            <div className="col-12 size-09 mt-3">
              <div className="mb-2 size-08">Marca</div>
              <div className="bg-grey border rounded px-3 py-2 bold-300">{order?.info?.vehicle?.brand?.label}</div>
            </div>
            <div className="col-12 size-09 mt-3">
              <div className="mb-2 size-08">Modelo</div>
              <div className="bg-grey border rounded px-3 py-2 bold-300">{order?.info?.vehicle?.model?.label}</div>
            </div>
            <div className="col-12 size-09 mt-3">
              <div className="mb-2 size-08">Patente</div>
              <div className="bg-grey border rounded px-3 py-2 bold-300 " style={{ textTransform: 'uppercase' }}>
                {order?.info?.vehicle?.plate}
              </div>
            </div>
            <div className="col-12 size-09 mt-3">
              <div className="mb-2 size-08">Año</div>
              <div className="bg-grey border rounded px-3 py-2 bold-300">{order?.info?.vehicle?.year}</div>
            </div>

            {order?.info?.vehicle?.size ? (
              <div className="col-12 size-09 mt-3">
                <div className="mb-2 size-08">Tamaño</div>
                <div className="bg-grey border rounded px-3 py-2 bold-300">{CARSIZES.find((item: any) => item.id === parseInt(order?.info?.vehicle?.size))?.label}</div>
              </div>
            ) : null}
          </>
        ) : null}

        {order?.info?.by_a_third && (
          <>
            <div className="col-12 size-09 mt-3">
              <div className="mb-2 size-09 bold">Quien recibe</div>
            </div>
            <div className="col-12 size-09 mt-3">
              <div className="mb-2 size-08">Nombre</div>
              <div className="bg-grey border rounded px-3 py-2 bold-300">{`${order?.info?.by_a_third.name_third}`}</div>
            </div>
            <div className="col-12 size-09 mt-3">
              <div className="mb-2 size-08">Teléfono</div>
              <div className="bg-grey border rounded px-3 py-2 bold-300">{`${order?.info?.by_a_third.phone_number_third}`}</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default OrderDetail;
