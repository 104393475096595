import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import MainRouter from "../src/routes/MainRouter";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import "./styles/_global.scss";
import Loader from "./components/Loader";

const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer />
      <Loader />
      <MainRouter />
    </Provider>
  );
};

export default App;
