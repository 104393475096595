import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ProductCard from '../../components/ProductCard'
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader'
import Orders from '../../services/order.service'
import serviceService from '../../services/service.service'

const AddProducts = (props: any) => {

    const [state, setState] = useState<any>({
        products: [],
        isEmpty: false,
        serviceDetails: null,
        total: 0
    })

    const { order, loader } = useSelector((state: any) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    useEffect(() => {
        getServicesDetails()
    }, [])

    const getServicesDetails = async () => {
        dispatch(LOADING_ON());
        try {
            const response: any = await serviceService.getDetails()
            const serviceDetails = response.data.data.find((item: any) => item.PK === order.parent_service_id);

            if (serviceDetails) {
                serviceDetails.related_products.map((item: any) => {
                    item.quantity = 0
                    return item
                })

                if (serviceDetails.related_products.length === 0) {
                    setState({ ...state, products: serviceDetails.related_products, serviceDetails: serviceDetails, isEmpty: true })
                } else {
                    setState({ ...state, products: serviceDetails.related_products, serviceDetails: serviceDetails })
                }
            } else {
                setState({ ...state, isEmpty: true })
            }
            dispatch(LOADING_OFF())
        } catch (e: any) {
            console.log('LOGGER', e.message)
            dispatch(LOADING_OFF())
        }
    }

    const _handlePlusQuantity = (PK: any) => {
        const { products } = state;
        const product = products.find((item: any) => item.PK === PK);
        if (product) {
            product.quantity = product.quantity + 1;
        }

        const total = products.reduce((acc: any, current: any) => {
            acc += current.quantity * current.price
            return acc
        }, 0);

        setState({ ...state, total: total || 0 })
    }

    const _handleMinusQuantity = (PK: any) => {
        const { products } = state;
        const product = products.find((item: any) => item.PK === PK);
        if (product) {
            product.quantity = product.quantity - 1;
        }

        const total = products.reduce((acc: any, current: any) => {
            acc += current.quantity * current.price
            return acc
        }, 0);

        setState({ ...state, total: total || 0 })
    }

    const _handleComplement = async () => {
        dispatch(LOADING_ON());
        try {
            const products = state.products.reduce((acc: any, current: any) => {
                if (current.quantity > 0) {
                    const product = {
                        id: current.PK,
                        name: current.name,
                        quantity_to_buy: current.quantity,
                        amount: current.price
                    }
                    acc.push(product)
                }
                return acc;
            }, [])

            const params = {
                order_id: order.PK,
                complement: {
                    products: products
                }
            }

            await Orders.complement(params);
            await Orders.changeStatus({ order_id: order.PK, status: 'delivered' });

            navigate('/?complement=ok')

            dispatch(LOADING_OFF());
        } catch (e: any) {
            console.log('LOGGER', e.message)
            toast.error('No fue posible agregar los productos seleccionados');
            dispatch(LOADING_OFF());
        }
    }

    return (
        <div className="container-fluid px-2">
            <div className="w-100 border bg-white py-3 rounded">
                {loader.loading && (
                    <div className="row mx-0 size-11 bold color-blue">
                        <div className="col-12 text-center">
                            Buscando productos...
                        </div>
                    </div>
                )}

                {!loader.loading && state.isEmpty && (
                    <div className="row mx-0 size-11 bold color-blue">
                        <div className="col-12 text-center">
                            No pudimos encontrar ningún producto complementario para este servicio.
                        </div>
                    </div>
                )}
                {!loader.loading && state.isEmpty === false && (
                    <>
                        <div className="row border-bottom mb-2 pb-2 mx-0">
                            <span className="px-3 size-12 bold color-blue">Servicio {state.serviceDetails?.name}</span>
                        </div>
                        {state.products.map((item: any) => (
                            <ProductCard data={item} key={item.PK} _handleMinusQuantity={_handleMinusQuantity} _handlePlusQuantity={_handlePlusQuantity} />
                        ))}

                        <div className="row justify-content-between color-black size-12 py-4 mx-0">
                            <div className="col">Subtotal</div>
                            <div className="col text-end bold color-blue">$ {state.total.toLocaleString('pt-BR')}</div>
                        </div>

                        <div className="row color-white px-4 mt-3">
                            <button className="btn btn-default bold py-2" disabled={state.total === 0} onClick={_handleComplement}>Finalizar</button>
                        </div>
                    </>
                )}
            </div>
        </div>
    )

}
export default AddProducts