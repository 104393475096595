/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import './component.profile-image.scss';

const ProfileImage = (props: any) => {

  const [state, setState] = useState<any>({ text: '' })
  const { _string } = props;

  useEffect(() => {
    const array = _string.split(' ');
    let text = '';
    if (array.length > 1) {
      text = `${array[0][0]} ${array[1][0]}`;
    } else {
      text = `${array[0][0]}`;
    }

    setState({ ...state, text })
  }, [_string])

  return (
    <div className="component-profile-image" style={{ textTransform: 'uppercase' }}>
      {state.text}
    </div>
  )

}
export default ProfileImage