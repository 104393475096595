import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import CardRouter from '../../components/CardRouter';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Orders from '../../services/order.service';
import RouterButton from '../../components/RouterButton';
import { FaFilter } from 'react-icons/fa';
import { DatePicker } from 'rsuite';
import ConfirmationModal from '../../components/ConfirmationModal';
import { clear_route } from '../../redux/actions/routes';

const RouterMaps = (props: any) => {
  const [state, setState] = useState<any>({
    orders: [],
    date_to: moment().add(10, "days").toDate(),
    date_from: moment().subtract(15, 'days').toDate(),
    filter_modal: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loader, routes } = useSelector((state: any) => state);

  useEffect(() => {
    const root = document.querySelector('#root-template-container');
    root?.addEventListener('scroll', (e: any) => {
      if (root.scrollTop > 100) {
        document.querySelector('.render-btn-component')?.classList.add('show');
      } else {
        document.querySelector('.render-btn-component')?.classList.remove('show');
      }
    });

    if (routes.length > 0) {
      navigate('/router/render');
      return;
    }
    _getOrders();
  }, []);

  const _getOrders = async (clear_route_action: any = false) => {
    dispatch(LOADING_ON());
    try {
      const response = await Orders.list({ status: "programmed", date_from: moment(state.date_from).format('YYYY-MM-DD'), date_to: moment(state.date_to).format('YYYY-MM-DD') });
      response.data = response.data.map((item: any) => {
        const [, order_status] = item?.GS1PK.split('-');
        item.status = order_status?.toLowerCase();
        return item;
      });
      const orders = response.data.filter((order: any) => order.status === 'programmed' || order.status === 'on_route' || order.status === 'rescheduled');
      const ordersWithDateStatus = orders.map((order: any) => {
        const delivery_date = order.info?.reservation?.schedule_at ? moment(order.info?.reservation?.schedule_at) : moment(order.created_at).add(1, 'days');
        const now = moment();
        let posibleStatus: any = {
          after: delivery_date.isAfter(now, 'day'),
          same: delivery_date.isSame(now, 'day'),
          before: delivery_date.isBefore(now, 'day'),
        };
        const status: any = Object.keys(posibleStatus).find((key: any) => posibleStatus[key] === true);
        order.delivery_status = status;
        order.delivery_day = delivery_date.format('DD/MM/YYYY HH:mm');
        return order;
      });
      setState({ ...state, orders: ordersWithDateStatus, filter_modal: false });

      if(clear_route_action) dispatch(clear_route())
    } catch (e: any) {
      toast.error(e.message);
    }
    dispatch(LOADING_OFF());
  };

  const _renderRouter = async () => {
    if (routes.length > 1) {
      navigate('/router/render');
    } else {
      toast.error('seleccione 2 o más pedidos antes de continuar');
    }
  };

  const _handleChange = async ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  return (
    <div className="container-fluid">

      <ConfirmationModal open={state.filter_modal} handleConfirm={() => _getOrders(true)} handleClose={() => setState({ ...state, filter_modal: false })}>
        <div className="d-flex flex-wrap w-100">
          <div className="col-12 mb-2">
            <span className="size-08 ms-1 bold-300">Desde</span>
            <DatePicker
              format="dd/MM/yyyy"
              placeholder="Informe la fecha"
              style={{ width: '100%' }}
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              value={state.date_from}
              onChange={(value: any) => _handleChange({ target: { value, name: 'date_from' } })}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: 'Hoy',
                yesterday: 'Ayer',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos'
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <span className="size-08 ms-1 bold-300">Hasta</span>
            <DatePicker
              format="dd/MM/yyyy"
              placeholder="Informe la fecha"
              style={{ width: '100%' }}
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              value={state.date_to}
              onChange={(value) => _handleChange({ target: { value, name: 'date_to' } })}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: 'Hoy',
                yesterday: 'Ayer',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos'
              }}
            />
          </div>
        </div>
      </ConfirmationModal>

      <div className="row px-3 mb-3 color-white">
        <div className="col-10 p-0 m-0">
          <button className="btn btn-default bold w-100" disabled={routes.length > 1 ? false : true} onClick={() => navigate('/router/render')}>
            <i className="bi bi-truck me-2 size-13" />
            Generar Ruta
          </button>
        </div>
        <div className="col-2 color-grey">
          <button className='btn border-bottom border-3 rounded' onClick={() => setState({ ...state, filter_modal: true })}>
            <FaFilter />
          </button>
        </div>
      </div>

      <RouterButton onClick={_renderRouter} />

      {state.orders &&
        state.orders.map((item: any) => (
          <div className="w-100 rounded shadow border mb-3" key={item.PK}>
            <CardRouter data={item} />
          </div>
        ))}

      {state.orders.length === 0 && !loader.loading && <div className="w-100 rounded shadow border mb-2 p-3 text-center">No se encontró ningún pedido programado...</div>}
    </div>
  );
};
export default RouterMaps;
