export const createWaypoints: any = async (routes: any, google: any) => {
  const waypoints: any = [];

  for (const item of routes) {
    try {
      const latLng = { lat: Number(item.info?.direction.latitude), lng: Number(item.info?.direction.longitude) };
      waypoints.push({
        location: latLng,
        stopover: true,
      });
    } catch (e: any) {
      waypoints.push({
        location: item.info?.direction._string,
        stopover: true,
      });
    }
  }

  const waypointsWithId = routes.map((item: any) => {
    return {
      location: item.info?.direction,
      stopover: true,
      PK: item.PK,
      SK: item.SK,
      delivery_status: item.delivery_status,
    };
  });

  return { waypoints, waypointsWithId };
};

export const convertAddressToLatLng = async (address: any, google: any) => {
  try {
    const geocoder = new google.maps.Geocoder();
    const geocodeResponse: any = await geocoder.geocode({ address: address });
    const latLng: any = geocodeResponse?.results[0]?.geometry?.location;
    return latLng;
  } catch (e: any) {
    return false;
  }
};
