import { createAction } from 'redux-actions';
import { clear_cache } from './cache';


export const SING_IN: any = createAction('SING_IN');
export const SING_OUT: any = createAction('SING_OUT');
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');

export const signin = (payload: any) => (dispatch: any) => {
    dispatch(SING_IN(payload));
    dispatch(SAVE_CHANGES())
}

export const signout = () => (dispatch: any) => {
    dispatch(SING_OUT());
    dispatch(clear_cache())
    dispatch(SAVE_CHANGES())
}

export const save = (payload: any) => (dispatch: any) => {
    dispatch(SAVE_CHANGES());
    return payload;
}