import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { SelectPicker } from 'rsuite';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import { set_order } from '../../redux/actions/order';
import Orders from '../../services/order.service';
import States from './states';
const State = (props: any) => {
  const params: any = useParams();
  const dispatch = useDispatch();
  const { order, select_status } = useSelector((state: any) => state);
  const [state, setState] = useState({
    order_state: '',
    status: [],
  });

  useEffect(() => {
    _handleOperatorStates();
  }, []);

  const _handleStatus = async () => {
    dispatch(LOADING_ON());
    try {
      if (!_checkState(order.status, state.order_state)) {
        dispatch(LOADING_OFF());
        toast.error('Este cambio de estado no es permitido');
        return;
      }
      const params = {
        order_id: order.PK,
        PK: order.PK,
        SK: order.SK,
        status: state.order_state,
      };
      await Orders.changeStatus(params);
      order.status = state.order_state;
      dispatch(set_order(order));
      toast.success('Estado actualizado con éxito...');
    } catch (e: any) {
      console.log('LOGGER', e);
      toast.error('No fue posible cambiar el estado');
    }
    dispatch(LOADING_OFF());
  };

  const _checkState = (actual_state: any, new_state: any) => {
    if (actual_state === new_state) return false;
    if (actual_state === 'new' && new_state === 'programmed') return true;
    if (actual_state === 'programmed' || actual_state === 'rescheduled') return true;
    if (actual_state === 'on_route' && new_state === 'programmed') return false;
    if (actual_state === 'on_route' && new_state === 'delivered') return true;
    if (actual_state === 'delivered') return false;
  };

  const _handleOperatorStates = () => {
    const status = select_status.filter(
      (s: any) => s.value !== 'canceled' && s.value !== 'NEW' && s.value !== 'same' && s.value !== 'before' && s.value !== 'after' && s.value !== 'all' && s.value !== 'rescheduled'
    );
    setState({ ...state, order_state: order.status, status });
  };

  const _handleChange = (value: any) => {
    setState({ ...state, order_state: value });
  };

  return (
    <div className="container-fluid">
      <div className="w-100 bg-white rounded border shadow p-3">
        <div className="row size-09 mb-5">
          <div className="col-12 color-blue">Pedido N°: {params.id}</div>

          <div className="col-12 size-09 mt-2">
            <i className="bi bi-truck me-2" />
            Estado: {select_status?.find((s: any) => s.value === order?.status)?.label}
          </div>
          <div className="col-12 size-09 mt-2">
            <i className="bi bi-geo-alt me-2" />
            Dirección:
            <a href={`https://waze.com/ul?ll=${order?.info?.direction?.latitude},${order?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank">
              {' '}
              {order?.info?.direction?._string}
            </a>
          </div>
        </div>

        <div className="row">
          <SelectPicker
            data={state.status}
            placeholder="Estados"
            value={state.order_state}
            onChange={_handleChange}
            cleanable={false}
            locale={{
              emptyMessage: 'Vacio',
              searchPlaceholder: 'Buscar',
              noResultsText: 'Ningún resultado encontrado',
              placeholder: 'Estados',
            }}
          />
        </div>

        <div className="row mt-4">
          <div className="col-12 color-white">
            <button className="btn btn-default bold-300 w-100 rounded-10 py-2" onClick={_handleStatus}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default State;
