import { createAction } from 'redux-actions';


export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');
export const ADD_TO_CACHE: any = createAction('ADD_TO_CACHE');
export const CLEAR_CACHE: any = createAction('CLEAR_CACHE');


export const set_cache = (payload: any) => (dispatch: any) => {
    dispatch(ADD_TO_CACHE(payload))
    dispatch(SAVE_CHANGES());
    return payload;
}

export const clear_cache = () => (dispatch: any) => {
    dispatch(CLEAR_CACHE())
    dispatch(SAVE_CHANGES());
}