import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AddProducts from '../pages/AddProducts';
import SignIn from '../pages/Auth';
import Home from '../pages/Home';
import Maps from '../pages/Maps';
import Order from '../pages/Order';
import OrderDetail from '../pages/OrderDetail';
import OrderMap from '../pages/OrderMap';
import RouterMaps from '../pages/RouterMaps';
import RouterMapsRender from '../pages/RouterMapsRender';
import State from '../pages/State';
import UpdateOrder from '../pages/UpdateOrder';
import MainTemplate from '../templates/MainTemplate';
import CallbackFusionAuth from '../pages/CallbackFusionAuth';
import Logout from '../pages/Logout';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import authConfig from '../helpers/authConfig';
import { useEffect, useState } from 'react';
import NotFoundUser from '../pages/NotFoundUser';

const MainRouter = (props: any) => {

  const userManager: any = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    loadUserInfo: true,
    ...authConfig,
  });

  userManager.events.addAccessTokenExpired(() => {
    try {
      userManager.signoutRedirect()
    } catch (e: any) {
      window.location.href = '/logout'
    }
  });

  userManager.signinSilentCallback(window.location.href);

  function authorize() {
    userManager.signinRedirect();
  }

  function clearAuth() {
    userManager.signoutRedirect();
  }

  const _redirectLogout = () => {
    window.location.href = '/logout'
  }

  useEffect(() => {
    window.addEventListener('logout_start', _redirectLogout)
    return () => {
      window.removeEventListener('logout_start', _redirectLogout)
    }
  }, [])


  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MainTemplate dontRenderBackButton authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <Home />
            </MainTemplate>
          }
        />
        <Route
          path="/order/:id"
          element={
            <MainTemplate authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <Order />
            </MainTemplate>
          }
        />
        <Route
          path="/orders/map"
          element={
            <MainTemplate authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <OrderMap />
            </MainTemplate>
          }
        />
        <Route
          path="/maps/:id"
          element={
            <MainTemplate authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <Maps />
            </MainTemplate>
          }
        />
        <Route
          path="/state/:id"
          element={
            <MainTemplate authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <State />
            </MainTemplate>
          }
        />
        <Route
          path="/order/details/:id"
          element={
            <MainTemplate authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <OrderDetail />
            </MainTemplate>
          }
        />
        <Route
          path="/order/update/:id"
          element={
            <MainTemplate authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <UpdateOrder />
            </MainTemplate>
          }
        />
        <Route
          path="/order/add-products/:id"
          element={
            <MainTemplate authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <AddProducts />
            </MainTemplate>
          }
        />
        <Route
          path="/router"
          element={
            <MainTemplate authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <RouterMaps />
            </MainTemplate>
          }
        />
        <Route
          path="/router/render"
          element={
            <MainTemplate dontRenderBackButton authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
              <RouterMapsRender />
            </MainTemplate>
          }
        />
        <Route
          path="/auth/callback"
          element={
            <CallbackFusionAuth userManager={userManager} authorize={authorize} clearAuth={clearAuth} />
          }
        />
        <Route
          path="/logout"
          element={
            <Logout authorize={authorize} clearAuth={clearAuth} />
          }
        />

        <Route
          path="/user/not-found"
          element={
            <NotFoundUser authorize={authorize} clearAuth={clearAuth} />
          }
        />
        <Route path="*" element={
          <MainTemplate dontRenderBackButton authorize={authorize} clearAuth={clearAuth} userManager={userManager}>
            <>Nothing Here!</>
          </MainTemplate>} />
      </Routes>
    </BrowserRouter>
  );
};
export default MainRouter;
