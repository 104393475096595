import { handleActions } from 'redux-actions';

import { ADD_TO_CACHE, CLEAR_CACHE } from '../actions/cache';

export const INITIAL_STATE: any = {}

export default handleActions({
    [ADD_TO_CACHE]: (state: any, action: any) => {
        return { ...state, ...action.payload };
    },
    [CLEAR_CACHE]: () => {
        return { ...INITIAL_STATE };
    }
}, INITIAL_STATE);
