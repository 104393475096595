import moment from 'moment';
import { set_cache } from '../redux/actions/cache';
import store from '../redux/store';
import instance from './api_account';
import instanceOvert from './api_overt';
import { validateAndFormatPhoneNumber } from '../helpers/utils';
import { getSession } from '../helpers/session';
class OrdersService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  list = async (payload: any = null) => {
    this._setHeaders();

    try {
      let params: any = [];
      let response: any = { data: { data: [] } };

      if (payload?.status && payload.status === 'programmed') {
        Object.keys(payload).forEach((key: any) => {
          params.push(`${key}=${payload[key]}`);
        });
        const programmed: any = await instance.get(`/account/operator/orden/list?${params.join('&')}`);

        params = [];
        payload.status = 'rescheduled';
        Object.keys(payload).forEach((key: any) => {
          params.push(`${key}=${payload[key]}`);
        });
        const rescheduled: any = await instance.get(`/account/operator/orden/list?${params.join('&')}`);

        response.data.data = [...programmed.data.data, ...rescheduled.data.data];
      } else {
        if (payload) {
          Object.keys(payload).forEach((key: any) => {
            params.push(`${key}=${payload[key]}`);
          });
        }
        response = await instance.get(`/account/operator/orden/list?${params.join('&')}`);
      }

      /**
       * Mutation phone number
       */
      if (response.data?.data) {
        response.data.data?.forEach((order: any) => {
          const { user } = order;
          if (user) {
            user.phone_formated = validateAndFormatPhoneNumber(user.phone)[0];
            user.phone_number_formated = validateAndFormatPhoneNumber(user.phone_number)[0];
            user.phone_number_plain = validateAndFormatPhoneNumber(user.phone_number)[1];
            user.phone_number_plain = validateAndFormatPhoneNumber(user.phone_number)[1];
          }
        });
      }
      return response.data;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  changeStatus = async (params: any) => {
    this._setHeaders();
    try {
      params.status = params.status?.toLowerCase();
      const response = await instance.post('/account/order/update/state', params);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  getDetails = async (PK: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/account/orden/${PK}`);
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  detailsOrden = async (payload: any) => {
    this._setHeaders();
    try {
      const response = await instance.get(`/account/operator/orden/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);

      /**
       * Mutation phone number
       */
      const { user } = response.data?.data;
      if (user) {
        user.phone_formated = validateAndFormatPhoneNumber(user.phone)[0];
        user.phone_number_formated = validateAndFormatPhoneNumber(user.phone_number)[0];
        user.phone_number_plain = validateAndFormatPhoneNumber(user.phone_number)[1];
        user.phone_number_plain = validateAndFormatPhoneNumber(user.phone_number)[1];
      }
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };

  paymentDetails = async (PK: any) => {
    try {
      const response: any = await instanceOvert.get(`/overt/payment/${PK}`);
      return response;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  complement = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.put('/account/operator/orden/complement', params);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  updateOrder = async (params: any) => {
    this._setHeaders();
    try {
      const response = await instance.put('/account/orden/custom', params);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
}

const Orders = new OrdersService();
export default Orders;
