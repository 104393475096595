import Images from "../../assets/images";

const NotFoundUser = ({ authorize, clearAuth, userManager }: any) => {

    const _redirectLogout = () => {
        window.location.href = '/logout';
    }

    return (
        <div className="container-fluid">
            <div className="row justify-content-center align-items-center" style={{ height: "100vh" }}>
                <div className="col-12 d-flex flex-column border rounded p-5 shadow" style={{ maxWidth: "100%", width: "450px" }}>
                    <div className="w-100 text-center">
                        <img alt={Images.Logo.alt} src={Images.Logo.src} style={{ width: '150px' }} />
                    </div>
                    <div className="text-center mt-3 bold color-dark-blue">
                        Este usuario no tiene acceso a la plataforma.
                    </div>

                    <div className="d-flex justify-content-center mt-5 color-white">
                        <button className="btn btn-default bold-300 rounded-10 py-2 px-4" onClick={_redirectLogout}>
                            Volver al inicio
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NotFoundUser;