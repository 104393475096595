import { useNavigate } from "react-router"
import Header from "../components/Header"
import { useEffect, useState } from "react"

const MainTemplate = (props: any) => {

    const { dontRenderBackButton, authorize, clearAuth, userManager } = props
    const navigate = useNavigate()

    const [isAuthorized, setIsAuthorized] = useState(false)

    useEffect(() => {
        checkIfIsAuthorized()
    }, [])

    const checkIfIsAuthorized = async () => {
        try {
            const user = await userManager.getUser()
            if (!user) {
                throw new Error('User not found')
            }
            setIsAuthorized(true)
        } catch (e: any) {
            setIsAuthorized(false)
            authorize();
        }
    }

    if (!isAuthorized) return null

    return (
        <div className="container-fluid p-0 m-0">
            <Header authorize={authorize} clearAuth={clearAuth} />
            <div className="py-3" id="root-template-container" style={{ height: 'calc(100vh - 70px)', overflowY: 'auto', overflowX: 'hidden' }}>
                {!dontRenderBackButton && (
                    <div className="w-100 px-3 py-2">
                        <button className="btn" onClick={() => navigate(-1)}>
                            <i className="bi bi-backspace me-2"></i> Volver
                        </button>
                    </div>
                )}
                {props.children}
            </div>
        </div>
    )

}
export default MainTemplate