import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DatePicker, SelectPicker } from 'rsuite';
import DeliveryEnded from '../../components/DeliveryEnded';
import InitiateDelivery from '../../components/InitiateDelivery';
import ModalComponent from '../../components/Modal';
import OrderCard from '../../components/OrderCard';
import { sort_order } from '../../helpers/sort';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import { set_status } from '../../redux/actions/select_status';
import Options from '../../services/options.service';
import Orders from '../../services/order.service';
import { set_filter } from '../../redux/actions/filters';

const Home = (props: any) => {
  const dispatch = useDispatch();
  const { loader, session, filters } = useSelector((state: any) => state);
  const location = useLocation();
  const [state, setState] = useState<any>({
    order_states: [],
    orders: [],
    orders_filtered: [],
    inputs: {
      state: 'programmed',
      date_from: moment().subtract(15, 'days').toDate(),
      date_to: moment().add(10, "days").toDate(),
    },
    modal: {
      routeOn: false,
      delivered: false,
    },
  });

  useEffect(() => {
    if (session.isLogged) {
      _getData();
    }
  }, []);

  const _handleChange = async ({ target }: any) => {
    const { name, value } = target;
    state.inputs[name] = value;
    setState({ ...state });
    if (name === 'state') dispatch(set_filter({ filter_order_status: value }));
  };

  const _handleSearchData = async () => {
    try {

      const dateFrom = moment(state.inputs.date_from);
      const dateTo = moment(state.inputs.date_to);

      if (dateFrom.isAfter(dateTo)) {
        toast.error('La fecha desde no puede ser mayor a la fecha hasta');
        return false;
      }

      if (dateFrom.isSame(dateTo)) {
        toast.error('La fecha desde no puede ser igual a la fecha hasta');
        return false;
      }

      if (dateTo.diff(dateFrom, 'days') > 30) {
        toast.error('El rango de fechas no puede ser mayor a 30 días');
        return false;
      }


      dispatch(LOADING_ON());
      const orders = await Orders.list({ status: state.inputs.state, date_from: moment(state.inputs.date_from).format('YYYY-MM-DD'), date_to: moment(state.inputs.date_to).format('YYYY-MM-DD') });


      const ordersWithDateStatus = orders.data.map((order: any) => {
        const [, order_status] = order.GS1PK?.split('-');
        order.status = order_status?.toLowerCase();
        let delivery_date: any = null;

        if (order.status === 'delivered') {
          delivery_date = moment(order.updated_at);
        } else {
          delivery_date = order.info?.reservation?.schedule_at ? moment(order.info?.reservation?.schedule_at) : moment(order.created_at).add(1, 'days');
        }

        const now = moment();
        let posibleStatus: any = {
          after: delivery_date.isAfter(now, 'day'),
          same: delivery_date.isSame(now, 'day'),
          before: delivery_date.isBefore(now, 'day'),
        };

        const status: any = Object.keys(posibleStatus).find((key: any) => posibleStatus[key] === true);
        order.delivery_status = status;
        order.delivery_day = delivery_date.format('DD/MM/YYYY HH:mm');
        order.sort = delivery_date.format();

        /** CHECK IF THE ITEM HAS BEEN EDITED AND IF THE ITEM HAS LITER */

        let quantity_kerosene_items = 0;
        let has_other_items = false;

        // Check if exist related products
        if (order.items && order.items.length > 2) has_other_items = true;

        const hasKeroseneMainService = order.items.find((item: any) => item.info?.GS1PK?.info?.liter && parseInt(item.info?.GS1PK?.info?.liter) > 0);

        if (hasKeroseneMainService) quantity_kerosene_items = hasKeroseneMainService.info?.quantity_to_buy;

        if (hasKeroseneMainService && order.complement && order.complement.length > 0) {
          const orderEdited = order.complement[0];
          const hasKEroseneMainServiceEdited = orderEdited.info?.items?.find((item: any) => item.PK === hasKeroseneMainService.PK && item.SK === hasKeroseneMainService.SK);
          if (hasKEroseneMainServiceEdited) quantity_kerosene_items = hasKEroseneMainServiceEdited.quantity_to_buy;
        }

        order.amount_to_pay = 0;

        // Pago presencial

        if (order?.payment?.payment_type === 1) {
          order.amount_to_pay += order.amount_shipping;
          order.amount_to_pay += order.amount;
          order.amount_to_pay -= order.amount_off;

          if (order.complement?.length > 0) {
            order.amount_to_pay -= order.complement[0].amount_diff;
          }
        }

        // Pago online
        if (order?.payment?.payment_type !== 1 && order.complement?.length > 0) {
          order.amount_to_pay -= order.complement[0].amount_diff
        }

        // Check if exist related products
        if (order.complement && order.complement.length > 0) {
          const orderEdited = order.complement[0];
          const validItems = orderEdited.info?.items?.filter((item: any) => item.quantity_to_buy > 0);
          has_other_items = validItems.length > 2 ? true : false;
        }

        order.quantity_kerosene_items = quantity_kerosene_items;
        order.has_other_items = has_other_items;
        return order;
      });

      ordersWithDateStatus.sort((a: any, b: any) => {
        return moment(a.sort).diff(moment(b.sort));
      })

      setState({ ...state, orders_filtered: ordersWithDateStatus, orders: ordersWithDateStatus });
      dispatch(LOADING_OFF());
    } catch (e: any) {
      dispatch(LOADING_OFF());
    }
  }

  const _getData = async (modal: any = null) => {
    dispatch(LOADING_ON());
    try {
      const status: any = filters.filter_order_status || 'programmed';
      const orders = await Orders.list({ status, date_from: moment(state.inputs.date_from).format('YYYY-MM-DD'), date_to: moment(state.inputs.date_to).format('YYYY-MM-DD') });
      const states = await Options.getOrderState();

      const ordersWithDateStatus = orders.data.map((order: any) => {

        const [, order_status] = order.GS1PK?.split('-');
        order.status = order_status?.toLowerCase();
        let delivery_date: any = null;

        if (order.status === 'delivered') {
          delivery_date = moment(order.updated_at);
        } else {
          delivery_date = order.info?.reservation?.schedule_at ? moment(order.info?.reservation?.schedule_at) : moment(order.created_at).add(1, 'days');
        }

        const now = moment();
        let posibleStatus: any = {
          after: delivery_date.isAfter(now, 'day'),
          same: delivery_date.isSame(now, 'day'),
          before: delivery_date.isBefore(now, 'day'),
        };

        const status: any = Object.keys(posibleStatus).find((key: any) => posibleStatus[key] === true);
        order.delivery_status = status;
        order.delivery_day = delivery_date.format('DD/MM/YYYY HH:mm');
        order.sort = delivery_date.format();

        /** CHECK IF THE ITEM HAS BEEN EDITED AND IF THE ITEM HAS LITER */

        let quantity_kerosene_items = 0;
        let has_other_items = false;

        // Check if exist related products
        if (order.items && order.items.length > 2) has_other_items = true;

        const hasKeroseneMainService = order.items.find((item: any) => item.info?.GS1PK?.info?.liter && parseInt(item.info?.GS1PK?.info?.liter) > 0);

        if (hasKeroseneMainService) quantity_kerosene_items = hasKeroseneMainService.info?.quantity_to_buy;

        if (hasKeroseneMainService && order.complement && order.complement.length > 0) {
          const orderEdited = order.complement[0];
          const hasKEroseneMainServiceEdited = orderEdited.info?.items?.find((item: any) => item.PK === hasKeroseneMainService.PK && item.SK === hasKeroseneMainService.SK);
          if (hasKEroseneMainServiceEdited) quantity_kerosene_items = hasKEroseneMainServiceEdited.quantity_to_buy;
        }

        order.amount_to_pay = 0;

        // Pago presencial

        if (order?.payment?.payment_type === 1) {
          order.amount_to_pay += order.amount_shipping;
          order.amount_to_pay += order.amount;
          order.amount_to_pay -= order.amount_off;

          if (order.complement?.length > 0) {
            order.amount_to_pay -= order.complement[0].amount_diff;
          }
        }

        // Pago online
        if (order?.payment?.payment_type !== 1 && order.complement?.length > 0) {
          order.amount_to_pay -= order.complement[0].amount_diff
        }

        // Check if exist related products
        if (order.complement && order.complement.length > 0) {
          const orderEdited = order.complement[0];
          const validItems = orderEdited.info?.items?.filter((item: any) => item.quantity_to_buy > 0);
          has_other_items = validItems.length > 2 ? true : false;
        }

        order.quantity_kerosene_items = quantity_kerosene_items;
        order.has_other_items = has_other_items;
        return order;
      });

      dispatch(set_status([...states.data]));

      ordersWithDateStatus.sort((a: any, b: any) => {
        return moment(a.sort).diff(moment(b.sort));
      })

      setState({ ...state, order_states: [...states.data], orders: ordersWithDateStatus, orders_filtered: ordersWithDateStatus, inputs: { ...state.inputs, state: status } });

      if (location.search?.includes('update_order=ok') && !modal) {
        window.history.pushState({}, '', '/');
        toast.success('Orden editada con éxito.');
        setTimeout(() => {
          toast.success('Estado del pedido cambiado a entregado.');
        }, 600);
      }
    } catch (e: any) {
      toast.error(e.message);
      dispatch(LOADING_OFF());
      return false;
    }
    dispatch(LOADING_OFF());
  };

  return (
    <div className="container-fluid">
      <ModalComponent open={state.modal.routeOn} handleClose={() => setState({ ...state, modal: { ...state.modal, routeOn: false } })}>
        <InitiateDelivery />
      </ModalComponent>

      <ModalComponent open={state.modal.delivered} handleClose={() => setState({ ...state, modal: { ...state.modal, delivered: false } })}>
        <DeliveryEnded />
      </ModalComponent>

      <div className="row">
        <div className="col-12 mb-2">
          <span className="size-08 ms-1 bold-300">Desde</span>
          <DatePicker
            format="dd/MM/yyyy"
            placeholder="Informe la fecha"
            style={{ width: '100%' }}
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            value={state.inputs?.date_from}
            onChange={(value: any) => _handleChange({ target: { value, name: 'date_from' } })}
            locale={{
              sunday: 'Dom',
              monday: 'Lun',
              tuesday: 'Mar',
              wednesday: 'Mie',
              thursday: 'Jue',
              friday: 'Vie',
              saturday: 'Sab',
              ok: 'OK',
              today: 'Hoy',
              yesterday: 'Ayer',
              hours: 'Horas',
              minutes: 'Minutos',
              seconds: 'Segundos'
            }}
          />
        </div>
        <div className="col-12 mb-2">
          <span className="size-08 ms-1 bold-300">Hasta</span>
          <DatePicker
            format="dd/MM/yyyy"
            placeholder="Informe la fecha"
            style={{ width: '100%' }}
            classPrefix="copec"
            className="rs-copec-cleanable"
            cleanable={false}
            value={state.inputs?.date_to}
            onChange={(value) => _handleChange({ target: { value, name: 'date_to' } })}
            locale={{
              sunday: 'Dom',
              monday: 'Lun',
              tuesday: 'Mar',
              wednesday: 'Mie',
              thursday: 'Jue',
              friday: 'Vie',
              saturday: 'Sab',
              ok: 'OK',
              today: 'Hoy',
              yesterday: 'Ayer',
              hours: 'Horas',
              minutes: 'Minutos',
              seconds: 'Segundos'
            }}
          />
        </div>
      </div>

      <div className="row mb-4">
        <span className="size-08 ms-1 bold-300">Estado</span>
        <SelectPicker
          data={state.order_states}
          classPrefix="copec"
          value={state.inputs.state}
          onChange={(e: any) => _handleChange({ target: { name: 'state', value: e } })}
          placeholder="Elija un estado"
          cleanable={false}
          className="rs-copec-cleanable"
          locale={{
            emptyMessage: 'Vacio',
            searchPlaceholder: 'Buscar',
            noResultsText: 'Ningún resultado encontrado',
            placeholder: 'Estados',
          }}
        />
      </div>

      <div className="row mb-5">
        <div className="col-12 color-white text-end">
          <button className='btn btn-default bold-300 w-100 rounded-10 py-2' onClick={_handleSearchData}>Buscar</button>
        </div>
      </div>

      {state && state.orders_filtered.length === 0 && loader.loading === false && (
        <div className="row p-3 bg-white mx-2 mt-3 rounded shadow text-center">
          <div className="col-12">No se encontró ningún pedido</div>
        </div>
      )}

      {state.orders_filtered &&
        state.orders_filtered.map((order: any) => (
          <div className="row p-3" key={order.PK}>
            <OrderCard data={order} states={state.order_states} updateOrders={_getData} />
          </div>
        ))}
    </div>
  );
};
export default Home;
