import { useNavigate } from 'react-router'
import Images from '../../assets/images'
import Menu from '../Menu'

const Header = ({ authorize, clearAuth }: any) => {

    const navigate = useNavigate()

    return (
        <div className="row px-4 shadow-bottom" style={{ height: '70px', display: 'flex', alignItems: 'center' }}>
            <div className="col-4" style={{ zIndex: 10 }}>
                <button className="btn" onClick={() => navigate('/')}>
                    <img alt={Images.Logo.alt} src={Images.Logo.src} style={{ width: '150px' }} />
                </button>
            </div>
            <div className="col-8">
                <Menu authorize={authorize} clearAuth={clearAuth} />
            </div>
        </div>
    )

}
export default Header