import { createAction } from 'redux-actions';


export const SET_ORDER: any = createAction('SET_ORDER');
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');


export const set_order = (payload: any) => (dispatch: any) => {
    dispatch(SET_ORDER(payload));
    dispatch(SAVE_CHANGES());
    return payload;
}