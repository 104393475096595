export function validateAndFormatPhoneNumber(number: any) {
  let fixed: any = number;
  if (number?.indexOf('5656') === 0 && number.length > 11) {
    fixed = number?.substring(2);
  }

  let formated: any = '(' + fixed.substr(0, 3) + ') ' + fixed.substr(3, 4) + '-' + fixed.substr(7, 4);

  return [formated, fixed];
}
