import axios from "axios";
// import { signout } from "../redux/actions/session";
// import store from '../redux/store'

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_OVERT,
  headers: {
    "Content-type": "application/json",
    'gux-api-key-overt': process.env.REACT_APP_API_KEY_OVERT || ''
  }
});

instance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    if (error.response && (error.response.status === 403 || error.response.status === 401 || error.response.status === 400)) {
      window.dispatchEvent(new Event('logout_start'));
    }
    return Promise.reject(error);
  }
);

export default instance