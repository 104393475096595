import './style.scss'
const QuantitySelect = (props: any) => {
  const { quantity, handlePlus, handleMinus } = props

  if (quantity === 0) {
    return (
      <button className="btn border size-09 py-1 w-100 bold" onClick={handlePlus} >
        Agregar
      </button>
    )
  }
  return (
    <div className="component-quantity d-flex pb-2 px-1" style={{ maxWidth: '150px' }}>
      <button className="component-quantity__actions" onClick={handleMinus}>{'-'}</button>
      <div className="component-quantity__input bold-300 color-grey">{quantity}</div>
      <button className="component-quantity__actions" onClick={handlePlus}>{'+'}</button>
    </div>
  )
}

export default QuantitySelect