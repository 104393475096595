import { Loader } from '@googlemaps/js-api-loader'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Images from '../../assets/images'

const Maps = (props: any) => {

    const [state, setState] = useState<any>({})
    const { order } = useSelector((state: any) => state)
    const mapRef = useRef<any>(null)
    const params: any = useParams();

    useEffect(() => {
        const loader: any = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : "",
            version: "weekly",
            libraries: ['places'],
            ...{},
        });

        loader.load().then(async (google: any) => {
            const location = { lat: order?.info?.direction?.latitude, lng: order?.info?.direction?.longitude };
            const map = new google.maps.Map(mapRef.current, {
                center: location,
                zoom: 16,
            });

            const marker = new google.maps.Marker({
                position: location,
                map: map,
                icon: {
                    url: Images.Marker.src,
                    anchor: new google.maps.Point(70, 70)
                }
            })
            setState({ ...state, google, map, api_key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY })
        });
    }, [])

    return (
        <div className="container-fluid px-3">
            <div className="w-100 bg-white p-3 rounded border-default shadow">

                <div className="row size-09 mb-4">

                    <div className="col-12 color-blue size-12 border-bottom mb-3 pb-2">
                        Pedido N°: {params.id}
                    </div>

                    <div className="col-12 size-09 mt-1">
                        <i className="bi bi-geo-alt me-2" />
                        Dirección:
                        <a href={`https://waze.com/ul?ll=${order?.info?.direction?.latitude},${order?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank"> {order?.info?.direction?._string}</a>
                    </div>
                </div>

                <div className="col-12 rounded-15" ref={mapRef} style={{ height: '60vh', maxHeight: '55vh', overflow: 'auto' }} />
            </div>
        </div>
    )

}
export default Maps