import Images from '../../assets/images'

const InitiateDelivery = (props: any) => {

    return (
        <div className="container-fluid">
            <div className="row mt-2">
                <div className="col-12 bold size-14 color-black text-center mb-3">
                    Despacho iniciado <br />con éxito
                        </div>
                <div className="col-12 text-center mb-4">
                    <img {...Images.Delivery} />
                </div>
            </div>
        </div>
    )

}
export default InitiateDelivery