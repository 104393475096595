import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router'
import moment from 'moment'
import { add_route, clear_route, remove_route } from '../../redux/actions/routes';
import InitiateDelivery from '../InitiateDelivery';
import ModalComponent from '../Modal';
import { Badge } from 'rsuite';

const RouterCard = (props: any) => {
    const { data } = props
    const navigate = useNavigate();
    const { routes } = useSelector((state: any) => state)
    const dispatch = useDispatch()

    const [state, setState] = useState<any>({
        modal: {
            routeOn: false
        },
        isSelected: false,
        bgColor: ''
    })

    useEffect(() => {
        const route = routes.find((item: any) => item.PK === data.PK);
        const bgColor = _setStatusColor()
        if (route) {
            setState({ ...state, isSelected: true, bgColor })
        } else {
            setState({ ...state, isSelected: false, bgColor })
        }


    }, [routes])

    const _setStatusColor = () => {
        const bgColors: any = {
            same: 'border-warning',
            after: 'border-light',
            before: 'border-danger'
        }

        const badge: any = {
            same: {
                color: 'yellow',
                content: 'Entregar hoy'
            },
            after: {
                color: 'blue',
                content: 'Programado'
            },
            before: {
                color: 'red',
                content: 'Atrasado'
            }
        }
        if (data.status !== 'delivered' && data.status !== 'canceled') {
            data.bgColor = bgColors[data.delivery_status]
            data.badge = badge[data.delivery_status]
        }

        if (data.status === 'delivered') {
            data.bgColor = 'border-success'
            data.badge = {
                color: 'green',
                content: 'Entregado'
            }
        }

        if (data.status === 'canceled') {
            data.bgColor = 'border-dark'
            data.badge = {
                color: 'violet',
                content: 'Anulado'
            }
        }

        if (bgColors[data.delivery_status]) {
            return bgColors[data.delivery_status]
        }

        return ''
    }

    const _handleModal = (modal: any) => {
        setState({ ...state, modal: { ...state.modal, [modal]: !state.modal[modal] } })
    }

    const _handleAddOrder = () => {
        dispatch(add_route(data))
    }

    const _handleRemoveOrder = () => {
        dispatch(remove_route(data.PK))
    }

    return (
        <div className={`container-fluid bg-white border-default rounded-10 px-2 py-3 position-relative ${state.bgColor}`}>

            <ModalComponent open={state.modal.routeOn} handleClose={() => _handleModal("routeOn")}>
                <InitiateDelivery />
            </ModalComponent>

            <div className="position-absolute" style={{ right: '10px', top: '5px' }}>
                <Badge {...data.badge} className="bold px-2" />
            </div>

            <div className="row px-2 py-4">
                <div className="col-12">
                    <div className="row">
                        <div className="col-12 color-blue bold-300">Pedido N: #{data.PK}</div>
                        <div className="col-12 size-08 mt-3">
                            <i className="bi bi-calendar me-2" />
                            Fecha Entrega:{data.info && data.info.reservation && data.info.reservation.schedule_at ? moment(data.info.reservation.schedule_at).format('DD/MM/YYYY') : moment(data.created_at).add(1, 'days').format('DD/MM/YYYY')}
                        </div>

                        <div className="col-12 size-08 mt-3">
                            <i className="bi bi-person me-2" />
                            Nombre: {data.user?.name}
                        </div>

                        <div className="col-12 size-08 mt-3 d-flex">
                            <i className="bi bi-geo-alt me-2" />
                            <span>
                                Dirección:
                                <a href={`https://waze.com/ul?ll=${data?.info?.direction?.latitude},${data?.info?.direction?.longitude}&z=10&navigate=yes`} target="_blank"> {data?.info?.direction?._string}</a>
                            </span>
                        </div>

                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 color-white">
                    {!state.isSelected && (
                        <button className="btn btn-default bold-300 w-100 rounded-10 py-2 d-flex justify-content-between align-items-center" onClick={_handleAddOrder}>
                            <span>Agregar a la ruta</span>
                            <i className="bi bi-arrow-right size-12" />
                        </button>
                    )}

                    {state.isSelected && (
                        <button className="btn btn-danger bold-300 w-100 rounded-10 py-2 d-flex justify-content-between align-items-center" onClick={_handleRemoveOrder}>
                            <span>Remover de la ruta</span>
                            <i className="bi bi-trash-fill size-12" />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )

}
export default RouterCard