import { handleActions } from 'redux-actions';

import { ADD_ROUTE, CLEAR_ROUTE, REMOVE_ROUTE } from '../actions/routes';

export const INITIAL_STATE: any = []

export default handleActions({
    [CLEAR_ROUTE]: (state: any, action: any) => {
        return []
    },
    [ADD_ROUTE]: (state: any, action: any) => {
        state.push(action.payload)
        return [...state]
    },
    [REMOVE_ROUTE]: (state: any, action: any) => {
        const index = state.findIndex((item: any) => item.PK === action.payload)
        if (index !== undefined) {
            state.splice(index, 1);
        }
        return [...state]
    }
}, INITIAL_STATE);
