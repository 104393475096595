import './styles.scss';

const RouterButton = (props: any) => {
    const { onClick, show, disabled } = props;
    return (
        <div className={`render-btn-component ${show ? 'show' : ''}`}>
            <button onClick={onClick} disabled={disabled}>
                <i className="bi bi-truck" />
            </button>
        </div>
    )
};

export default RouterButton;