import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ItemCard from '../../components/ItemCard';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Orders from '../../services/order.service';
import serviceService from '../../services/service.service';
import { FaPen } from 'react-icons/fa';
import { set_cache } from '../../redux/actions/cache';

const UpdateOrder = (props: any) => {
  const [state, setState] = useState<any>({
    products: [],
    sub_services: [],
    related_products: [],
    isEmpty: false,
    serviceDetails: null,
    total: 0,
    amount_shipping: 0,
    initial_total: 0,
    delivery: null,
    amount_discount: 0,
    diff: 0,
  });

  const sizes: any = [
    { size: 1, label: 'Pequeño' },
    { size: 2, label: 'Mediano' },
    { size: 3, label: 'Grande' },
    { size: 4, label: 'Extra Grande' },
  ];

  const { order, loader, cache, session } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    getServicesDetails();
  }, []);

  const _handleSumValues = (payload: any) => {
    return payload.reduce((acc: any, current: any) => {
      acc += current.quantity * current.price;
      return acc;
    }, 0);
  };

  const getServicesDetails = async () => {
    dispatch(LOADING_ON());
    try {
      // Get orders and service details
      const responseOrderDetails: any = await Orders.detailsOrden({ PK: searchParams.get('PK'), SK: searchParams.get('SK') });
      const responseOrderPayment: any = await Orders.paymentDetails(searchParams.get('PK')?.split('-')[0]);
      const order: any = responseOrderDetails.data;

      if (order.complement && order.complement?.length > 0) {
        toast.error('Esta orden ya fue editada.');
        navigate('/');
        return;
      }

      const eds_id: any = session?.profile?.info?.eds_id;
      const service_id: any = responseOrderDetails.data.GS4PK;

      let responseServiceDetails: any = null;

      responseServiceDetails = await serviceService.getServiceDetailed({ PK: eds_id, SK: service_id });

      const service: any = responseServiceDetails.data;

      const { sub_services, related_products } = service;

      // Order sub services by name
      sub_services.sort((a: any, b: any) => {
        if (a.name > b.name) return 1;
        if (a.name < b.name) return -1;
        return 0;
      });

      const { items } = order;

      // Filter items (remove delivery items)

      const orderItemsWithoutDelivery = items.filter((item: any) => item.info?.name?.toLowerCase() !== 'envio' && item.info?.name?.toLowerCase() !== 'envío');

      const deliveryItem: any = items.find((item: any) => item.info?.name?.toLowerCase() === 'envio' || item.info?.name?.toLowerCase() === 'envío');

      let delivery: any = null;

      if (deliveryItem) {
        delivery = {
          PK: deliveryItem.PK,
          SK: deliveryItem.SK,
          name: deliveryItem.info.name,
          price: deliveryItem.info.unit_price,
          quantity: deliveryItem.info.quantity_to_buy,
          amount_discount: deliveryItem.info.amount_discount,
        };
      }

      let filtered_sub_services: any = [];
      if (sub_services) {
        filtered_sub_services = sub_services.filter((item: any) => item.name?.toLowerCase() !== 'envio' && item.name?.toLowerCase() !== 'envío');
      }

      let amount_discount: any = order.amount_off;

      const orderItemsFormated: any = orderItemsWithoutDelivery.map((item: any) => {
        let main_item: any = false;

        if (service.type !== 'lub') {
          main_item = sub_services.find((ss: any) => ss.name === item.info.name);
        } else {
          main_item = sub_services.find((ss: any) => ss.name === item.info.name);
          if (main_item && main_item?.info?.air_filter) {
            main_item = false;
          }
        }

        const p: any = {
          PK: item.PK,
          SK: item.SK,
          name: item.info.name,
          price: item.info.unit_price,
          quantity: item.info.quantity_to_buy,
          amount_discount: item.info.amount_discount,
          disabled: false,
        };

        if (main_item) {
          const isMainProduct: any = main_item?.info?.required ? true : service.type !== 'kerosene' ? true : false;

          p.select_option = service.type !== 'kerosene'; // Mostrar lista o +/- component
          p._main = isMainProduct;
          p.service_type = service.type; // Tipo servicio
          p.is_sub_service = true; // Se es un sub_servicio o producto relacionado
        } else {
          p.select_option = false;
          p._main = false;
          p.service_type = service.type;
          p.is_sub_service = false;
        }

        if (p.select_option) {
          p.sub_services = filtered_sub_services.map((ss: any) => {
            let name = ss.name;

            if (service.type === 'lavamax') {
              name = name + ' - ' + sizes.find((size: any) => size.size === ss.info?.size)?.label;
            }

            return { label: `${name}`, value: ss.SK, air_filter: ss.info?.air_filter };
          });

          p.sub_services = p.sub_services.filter((ss: any) => !ss.air_filter);
        }

        if (responseOrderPayment.data?.cupon && responseOrderPayment.data?.cupon?.name) {
          p.disabled = false;
        }

        return p;
      });

      let relatedProducts: any = [];

      if (related_products) {
        relatedProducts = related_products.map((item: any) => {
          const p: any = {
            PK: item.PK,
            SK: item.SK,
            name: item.name,
            price: item.price,
            quantity: 0,
            select_option: false,
            _main: false,
            service_type: service.type,
            is_sub_service: false,
            active: item.active,
          };
          return p;
        });
      }

      relatedProducts = relatedProducts.filter((item: any) => item.active);

      let total = _handleSumValues(orderItemsFormated);
      total += _handleSumValues(relatedProducts);
      total += order.amount_shipping;

      orderItemsFormated.sort((a: any, b: any) => {
        if (a._main > b._main) return -1;
        if (a._main < b._main) return 1;
        return 0;
      });

      setState({
        ...state,
        products: [...orderItemsFormated],
        total,
        sub_services,
        related_products: relatedProducts,
        initial_total: total,
        amount_shipping: order.amount_shipping,
        delivery,
        service,
        amount_discount,
        diff: 0,
        payment: responseOrderPayment.data,
      });

      dispatch(LOADING_OFF());
    } catch (e: any) {
      console.log('LOGGER', e.message);
      toast.error('No se puede editar el pedido');
      dispatch(LOADING_OFF());
    }
  };

  const _handlePlusQuantity = async (payload: any, is_related_product: any = false) => {
    const { products, related_products, amount_shipping, service } = state;

    if (is_related_product) {
      const rp = related_products.find((item: any) => item.SK === payload.SK);
      rp.quantity = rp.quantity + 1;
    } else {
      const product = products.find((item: any) => item.SK === payload.SK);

      if (product) {
        if (product.service_type === 'kerosene') {
          if (product._main) {
            product.quantity = product.quantity + 1;
          } else {
            const secondary: any = service.sub_services.find((item: any) => item.name === product?.name);
            if (secondary) {
              const main: any = products.find((item: any) => item._main);
              if (product.quantity < main.quantity) {
                product.quantity = product.quantity + 1;
              } else {
                toast.error('No se puede agregar más bidones que Kerosene');
              }
            } else {
              product.quantity = product.quantity + 1;
            }
          }
        } else {
          product.quantity = product.quantity + 1;
        }
      }
    }

    let total = _handleSumValues(products);
    total += _handleSumValues(related_products);
    total += amount_shipping;

    const diff: any = state.initial_total - total;
    setState({ ...state, total: total || 0, diff });
  };

  const _handleMinusQuantity = async (payload: any, is_related_product: any = false) => {
    const { products, related_products, amount_shipping } = state;

    if (is_related_product) {
      const rp = related_products.find((item: any) => item.SK === payload.SK);
      rp.quantity = rp.quantity - 1 > 0 ? rp.quantity - 1 : rp.quantity;
    } else {
      const product = products.find((item: any) => item.SK === payload.SK);
      if (product.service_type === 'kerosene') {
        if (product._main) {
          const secondary: any = products.find((item: any) => item.is_sub_service && item._main === false);
          product.quantity = product.quantity - 1 > 0 ? product.quantity - 1 : product.quantity;
          if (secondary && product.quantity < secondary.quantity) {
            secondary.quantity = secondary.quantity - 1;
          }
        } else {
          product.quantity = product.quantity - 1 > 0 ? product.quantity - 1 : product.quantity;
        }
      } else {
        product.quantity = product.quantity - 1 > 0 ? product.quantity - 1 : product.quantity;
      }
    }

    let total = _handleSumValues(products);
    total += _handleSumValues(related_products);
    total += amount_shipping;
    const diff: any = state.initial_total - total;
    setState({ ...state, total: total || 0, diff });
  };

  const _handleChangeService = (current_service: any, SK_new_service: any) => {
    if (current_service) {
      const product: any = state.products.find((item: any) => item.SK === current_service.SK);
      const service: any = state.sub_services.find((item: any) => item.SK === SK_new_service);
      product.name = service.name;
      product.price = service.price;
      product.new_service_id = `${service.PK}-${service.SK}`;
      let total = _handleSumValues(state.products);
      total += _handleSumValues(state.related_products);
      total += state.amount_shipping;
      const diff: any = state.initial_total - total;
      setState({ ...state, total, diff });
    }
  };

  const _handleRemoveItem = (payload: any, is_related_product: any = false) => {
    const { products, related_products } = state;
    if (is_related_product) {
      const rp = related_products.find((item: any) => item.SK === payload.SK);
      rp.quantity = 0;
    } else {
      const product = products.find((item: any) => item.SK === payload.SK);
      product.quantity = 0;
    }
    let total = _handleSumValues(state.products);
    total += _handleSumValues(state.related_products);
    total += state.amount_shipping;
    const diff: any = state.initial_total - total;
    setState({ ...state, total, diff });
  };

  const _handleSubmitOrder = async () => {
    dispatch(LOADING_ON());
    try {
      const { products, related_products } = state;

      const items: any = products.map((element: any) => {
        const item: any = {};
        item.item_id = element.SK;
        item.quantity_to_buy = element.quantity;
        item.new_item_id = element.new_service_id ? element.new_service_id : null;
        item.name = element.name;
        item.price = element.price;
        item.amount_discount = element.amount_discount || 0;
        item.PK = element.PK;
        item.SK = element.SK;
        return item;
      });

      related_products.forEach((element: any) => {
        if (element.quantity > 0) {
          items.push({
            quantity_to_buy: element.quantity,
            new_item_id: `${element.PK}-${element.SK}`,
            item_id: null,
            name: element.name,
            price: element.price,
            amount_discount: element.amount_discount || 0,
            PK: element.PK,
            SK: element.SK,
          });
        }
      });

      if (state.delivery) {
        items.push({
          quantity_to_buy: 1,
          new_item_id: null,
          item_id: state.delivery?.SK,
          name: state.delivery?.name,
          price: state.delivery?.price,
          amount_discount: state.delivery?.amount_discount || 0,
          PK: state.delivery?.PK,
          SK: state.delivery?.SK,
        });
      }

      const newOrder: any = {
        PK: order.PK,
        SK: order.SK,
        items,
      };

      const responseUpdateOrder: any = await Orders.updateOrder(newOrder);

      if (responseUpdateOrder?.error?.length > 0) {
        throw new Error('No fue posible complementar el pedido.');
      }

      const { orders } = cache;
      if (orders) {
        const cacheOrderIndex: any = orders.findIndex((item: any) => item.data.PK === order.PK);
        if (cacheOrderIndex !== -1) {
          orders.splice(cacheOrderIndex, 1);
          dispatch(set_cache({ ...cache, orders }));
        }
      }
      // await Orders.changeStatus({ PK: order.PK, SK: order.SK, status: 'delivered' });
      toast.success('Orden editada con éxito.');
      dispatch(LOADING_OFF());
      navigate(`/order/${newOrder.PK}?PK=${newOrder.PK}&SK=${newOrder.SK}`);
    } catch (e: any) {
      console.log('LOGGER', e.message);
      toast.error('No fue posible editar esta orden');
      dispatch(LOADING_OFF());
    }
  };

  return (
    <div className="container-fluid px-2">
      <div className="w-100 border bg-white py-3 rounded">
        {loader.loading && (
          <div className="row mx-0 size-11 bold color-blue">
            <div className="col-12 text-center">Buscando información del pedido...</div>
          </div>
        )}

        {!loader.loading && state.isEmpty && (
          <div className="row mx-0 size-11 bold color-blue">
            <div className="col-12 text-center">No pudimos encontrar ningún producto complementario para este servicio.</div>
          </div>
        )}
        {!loader.loading && state.isEmpty === false && (
          <>
            <div className="row border-bottom my-2 pb-2 mx-0">
              <span className="px-3 size-12 bold color-blue">Items</span>
            </div>
            {state.products.map((item: any) => (
              <ItemCard
                data={item}
                key={item.SK}
                _handleMinusQuantity={_handleMinusQuantity}
                _handlePlusQuantity={_handlePlusQuantity}
                _handleChangeService={_handleChangeService}
                _removeItem={_handleRemoveItem}
                is_related_product={false}
                disabled={item.disabled}
              />
            ))}

            {state.related_products?.length > 0 && (
              <>
                <div className="row border-bottom pb-2 my-4 mx-0">
                  <span className="px-3 size-12 bold color-blue">Complementar Pedido</span>
                </div>
                {state.related_products.map((item: any) => (
                  <ItemCard
                    data={item}
                    key={item.SK}
                    _handleMinusQuantity={_handleMinusQuantity}
                    _handlePlusQuantity={_handlePlusQuantity}
                    _handleChangeService={_handleChangeService}
                    _removeItem={_handleRemoveItem}
                    is_related_product={true}
                  />
                ))}
              </>
            )}

            {state.delivery && (
              <>
                <div className="row border-bottom mb-2 py-2 mx-0">
                  <span className="px-3 size-12 bold color-blue">Despacho</span>
                </div>
                <ItemCard
                  data={state.delivery}
                  key={state.delivery?.SK}
                  _handleMinusQuantity={_handleMinusQuantity}
                  _handlePlusQuantity={_handlePlusQuantity}
                  _handleChangeService={_handleChangeService}
                  _removeItem={_handleRemoveItem}
                  is_related_product={false}
                  disabled
                />
              </>
            )}

            <div className="row justify-content-between color-black size-09 py-2 mx-0">
              <div className="col">Subtotal</div>
              <div className="col text-end bold color-blue">$ {state.total.toLocaleString('pt-BR')}</div>
            </div>

            {state.amount_discount > 0 && (
              <div className="row justify-content-between color-black size-09 py-2 mx-0">
                <div className="col">Descuento</div>
                <div className="col text-end bold color-blue">$ {state.amount_discount?.toLocaleString('pt-BR')}</div>
              </div>
            )}

            {state.payment?.payment_type === 1 ? (
              <div className="row justify-content-between color-black size-12 py-2 mx-0 mb-5">
                <div className="col bold ">Monto a cobrar</div>
                <div className="col text-end bold color-blue">$ {(state.total - (state.amount_discount || 0))?.toLocaleString('pt-BR')}</div>
              </div>
            ) : (
              <div className="row justify-content-between color-black size-09 py-2 mx-0 mb-5">
                <div className="col bold">{Math.sign(state.diff) === 1 ? 'Monto a reembolsar (Call center)' : 'Monto a cobrar'}</div>
                <div className="col text-end bold color-blue">$ {(Math.sign(state.diff) === -1 ? state.diff * -1 : state.diff)?.toLocaleString('pt-BR')}</div>
              </div>
            )}

            <div className="row color-white px-4 mt-3">
              <button className="btn btn-default bold py-2" disabled={state.total === 0} onClick={_handleSubmitOrder}>
                Finalizar
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export default UpdateOrder;
