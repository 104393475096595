import { createAction } from 'redux-actions';


export const SET_FILTERS: any = createAction('SET_FILTERS');
export const SAVE_CHANGES: any = createAction('SAVE_CHANGES');


export const set_filter = (payload: any) => (dispatch: any) => {
    dispatch(SET_FILTERS(payload));
    dispatch(SAVE_CHANGES());
    return payload;
}