import { createStore, combineReducers, applyMiddleware } from 'redux';
import { session, routes, loader, order, select_status, cache, filters, router } from './reducers';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

let intialState: any = {};
const storage: any = localStorage.getItem('global_state');
intialState = storage ? JSON.parse(storage) : {};

const reducers = combineReducers({ session, routes, loader, order, select_status, cache, filters, router });

const saver = (store: any) => (next: any) => (action: any) => {
  const stateToSave = store.getState();
  localStorage.setItem('global_state', JSON.stringify({ ...stateToSave }));
  next(action);
};

const store = createStore(reducers, intialState, composeWithDevTools(applyMiddleware(thunk, saver)));

export default store;
