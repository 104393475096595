import { Loader } from '@googlemaps/js-api-loader';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Images from '../../assets/images';
import ConfirmationModal from '../../components/ConfirmationModal';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Orders from '../../services/order.service';
import MapsCard from '../OrderMap/MapsCard';
import { convertAddressToLatLng, createWaypoints } from './routerHelpers';
import { clear_route } from '../../redux/actions/routes';
import { useNavigate } from 'react-router-dom';
import { add_route_configs, clear_route_configs } from '../../redux/actions/router';

const RouterMapsRender = (props: any) => {
  const [state, setState] = useState<any>({
    start_point: {},
    end_point: {},
    waypoints: [],
    routes: [],
    step: 1,
    loading: false,
    orden: {},
    modal: { open: false },
    error: false
  });

  const { routes, router, session } = useSelector((state: any) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mapRef = useRef<any>(null);

  useEffect(() => {
    const loader: any = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
      version: 'weekly',
      libraries: ['places'],
      ...{},
    });

    loader.load().then((google: any) => {
      state.google = google;
      state.routes = [...routes]
      state.step = 3;
      state.loading = true;
      state.error = false;
      setState({ ...state });
      _handleCreateRoutes(state, false);
    });

  }, []);

  const _handleSelectStart = (payload: any) => {
    const stateRoutes = state.routes.filter((route: any) => route.PK !== payload.PK);
    setState({ ...state, routes: stateRoutes, step: 2, start_point: payload });
  };

  const _handleSelectEnd = (payload: any) => {
    const stateRoutes = state.routes.filter((route: any) => route.PK !== payload.PK);
    state.routes = stateRoutes;
    state.step = 3;
    state.end_point = payload;
    state.loading = true;
    state.error = false;
    setState({ ...state });
    dispatch(add_route_configs({ start_point: state.start_point, end_point: state.end_point }));
    _handleCreateRoutes(state);
  };

  const _handleCreateRoutes = async (state: any, isManual: boolean = true) => {

    const { google } = state;

    const { waypoints, waypointsWithId } = await createWaypoints(routes, google);

    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer({
      markerOptions: {
        visible: false,
      },
    });

    let start_point: any, end_point: any;

    if (isManual) {
      start_point = state.start_point;
      end_point = state.end_point;
    }

    if (!isManual) {
      const { configs } = router;
      const latLngStartRoute: any = await convertAddressToLatLng(session?.session?.message?.eds?.info?.address, google)

      if (latLngStartRoute) {
        start_point = {
          info: {
            direction: {
              latitude: latLngStartRoute.lat(),
              longitude: latLngStartRoute.lng(),
              _string: session?.session?.message?.eds?.info?.address
            }
          }
        }
        end_point = { ...start_point }
      }

      if (!latLngStartRoute && configs.start_point) {
        start_point = configs.start_point;
        end_point = configs.end_point;
      }

      if (!latLngStartRoute && !configs.start_point) {
        setState((prevState: any) => ({ ...prevState, loading: false, step: 1, error: true }));
        return
      }
    }

    const map = new google.maps.Map(mapRef.current, {
      center: { lat: start_point.info?.direction.latitude, lng: start_point.info?.direction.longitude },
      zoom: 12,
    });

    directionsRenderer.setMap(map);

    directionsService
      .route({
        origin: { query: start_point.info?.direction._string },
        destination: { query: end_point.info?.direction._string },
        waypoints,
        optimizeWaypoints: true,
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response: any) => {
        directionsRenderer.setDirections(response);
        showSteps(google, map, start_point, end_point, waypointsWithId);
        setState({ ...state, loading: false });
      })
      .catch((e: any) => toast.error("No fue posible crear la ruta"));
  };

  const showSteps = (google: any, map: any, start: any, end: any, waypoints: any) => {
    addMarker(google, map, start, start?.PK ? false : true);
    addMarker(google, map, end, end?.PK ? false : true);

    waypoints.forEach((item: any) => {
      const icon = item.delivery_status === 'same' ? Images.MarkerHouseYellow.src : item.delivery_status === 'before' ? Images.MarkerHouseRed.src : Images.MarkerHouseBlue.src;
      var marker = new google.maps.Marker({
        map: map,
        position: { lat: item.location.latitude, lng: item.location.longitude },
        icon: icon,
      });

      google.maps.event.addListener(marker, 'click', async () => {
        const waypoint = routes.find((el: any) => el.PK === item.PK);
        try {
          dispatch(LOADING_ON());
          const orderDetail: any = await Orders.detailsOrden({ PK: item.PK, SK: item.SK });

          let total: any = orderDetail.data.amount + orderDetail.data.amount_shipping;

          if (orderDetail.data?.complement?.length > 0) {
            total -= orderDetail.data.complement[0].amount_diff;
            orderDetail.data.is_complemented = true;
          }

          const [, order_status] = orderDetail.data.GS1PK?.split('-');
          orderDetail.data.status = order_status?.toLowerCase();
          orderDetail.data.total_without_discount = total;

          const [paymentPK, _] = item.PK.split('-')
          const paymentDetails: any = await Orders.paymentDetails(paymentPK);

          orderDetail.data.paymentData = paymentDetails.data;

          orderDetail?.data?.items.sort((a: any, b: any) => {
            if (a.info?.type_item > b.info?.type_item) return 1;
            if (a.info?.type_item < b.info?.type_item) return -1;
            return 0;
          });

          setState((prevState: any) => {
            return { ...prevState, modal: { open: true }, order: { ...orderDetail.data, delivery_day: waypoint.delivery_day } };
          });
        } catch (e: any) {
          toast.error('No se puedo recuperar los datos de este pedido');
          dispatch(LOADING_OFF());
        }
        dispatch(LOADING_OFF());
      });
    });
  };

  const addMarker = (google: any, map: any, item: any, static_point: boolean = false) => {

    if (static_point) {
      const icon = Images.Flag.src;
      new google.maps.Marker({
        map: map,
        position: { lat: item.info?.direction.latitude, lng: item.info?.direction.longitude },
        icon: {
          url: icon,
          scaledSize: new google.maps.Size(50, 50),

        },
      });
    }
    if (!static_point) {
      const icon = item.delivery_status === 'same' ? Images.MarkerHouseYellow.src : item.delivery_status === 'before' ? Images.MarkerHouseRed.src : Images.MarkerHouseBlue.src;
      const m = new google.maps.Marker({
        map: map,
        position: { lat: item.info?.direction.latitude, lng: item.info?.direction.longitude },
        icon: icon,
      });
      google.maps.event.addListener(m, 'click', async () => {
        try {
          dispatch(LOADING_ON());
          const orderDetail: any = await Orders.detailsOrden({ PK: item.PK, SK: item.SK });
          setState((prevState: any) => {
            return { ...prevState, modal: { open: true }, order: { ...orderDetail.data, delivery_day: item.delivery_day } };
          });
        } catch (e: any) {
          toast.error('No se puedo recuperar los datos de este pedido');
          dispatch(LOADING_OFF());
        }
        dispatch(LOADING_OFF());
      });
    }
  };

  const _handleCloseModal = () => setState({ ...state, modal: { open: false } });

  const _handleClearRoute = () => {
    dispatch(clear_route());
    dispatch(clear_route_configs());
    navigate('/router')
  }

  return (
    <div className="container-fluid">
      <ConfirmationModal handleClose={_handleCloseModal} handleConfirm={_handleCloseModal} hideFooter open={state.modal.open} title="">
        <MapsCard state={state} />
      </ConfirmationModal>

      {state?.error ? (
        <div className="row">
          <div className="col-12">
            <div className="alert alert-danger alert-dismissible fade show">
              No se pudo encontrar un punto de inicio. Intente reiniciar su sesión o seleccion la primera y ultima entrega.
            </div>
          </div>
        </div>
      ) : null}

      <div className="row pb-4 px-3 text-white">
        <button className="btn btn-default bold" onClick={_handleClearRoute}>
          <i className="bi bi-truck me-2 size-13" />
          Generar Nueva Ruta
        </button>
      </div>

      <div className="bg-white shadow border rounded p-3">
        {state && state.step && state.step === 1 && (
          <div className="row px-3">
            <div className="col-12 bold mx-0 mb-3">Selecciona la primera entrega</div>

            {state?.routes?.map((item: any, index: any) => (
              <div className="row bg-grey border rounded p-3 mx-0 mb-2" key={item.PK}>
                <div className="col-12 ">
                  <button className="btn w-100 text-start" onClick={() => _handleSelectStart(item)}>
                    {item.info?.direction?._string}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {state && state.step && state.step === 2 && (
          <div className="row px-3">
            <div className="col-12 bold mx-0 mb-3">Selecciona la última entrega</div>

            {state?.routes?.map((item: any, index: any) => (
              <div className="row bg-grey border rounded p-3 mx-0 mb-2" key={item.PK}>
                <div className="col-12 ">
                  <button className="btn w-100 text-start" onClick={() => _handleSelectEnd(item)}>
                    {item.info?.direction?._string}
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        <div className={`row ${state.step === 3 ? 'd-flex' : 'd-none'}`}>
          {state.loading && (
            <div className="col-12 text-center bold mb-4">
              Generando ruta...
              <div className="spinner-border text-secondary size-09 ms-3" style={{ width: '18px', height: '18px' }} role="status" />
            </div>
          )}

          <div className="col-12 maps" ref={mapRef} style={{ height: state.loading ? 0 : '70vh' }}></div>
        </div>
      </div>
    </div>
  );
};
export default RouterMapsRender;
