import instance from './api_account';
import store from '../redux/store';
import moment from 'moment';
import { set_cache } from '../redux/actions/cache';
import { getSession } from '../helpers/session';

class Services {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  getServiceDetailed = async (payload: any) => {
    this._setHeaders();
    try {
      const response: any = await instance.get(`/account/service/detail/${payload.PK}?PK=${payload.PK}&SK=${payload.SK}`);
      return response.data;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };

  getDetails = async () => {
    this._setHeaders();
    const { session, cache } = store.getState();
    const dispatch: any = store.dispatch;

    try {
      const response: any = await instance.get('/account/operator/services', { headers: { userid: session.profile.PK } });
      const services: any = {
        updated_at: moment().toString(),
        data: response.data,
      };
      dispatch(set_cache({ ...cache, service_data: services }));
      return response;
    } catch (e: any) {
      console.log('LOGGER', e.message);
      return Promise.reject(e);
    }
  };
}

export default new Services();
