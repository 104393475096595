import { Loader } from '@googlemaps/js-api-loader';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DatePicker, SelectPicker } from 'rsuite';
import Images from '../../assets/images';
import ConfirmationModal from '../../components/ConfirmationModal';
import { LOADING_OFF, LOADING_ON } from '../../redux/actions/loader';
import Orders from '../../services/order.service';
import MapsCard from './MapsCard';
import { FaFilter } from 'react-icons/fa';

const OrderMap = (props: any) => {
  const [state, setState] = useState<any>({
    modal: { open: false },
    orders: [],
    order: {},
    markers: [],
    date_to: moment().add(10, "days").toDate(),
    date_from: moment().subtract(15, 'days').toDate(),
    filter_modal: false,
    selected_status: 'all',
    status: [
      { label: 'Todos', value: 'all' },
      { label: 'Atrasado', value: 'before' },
      { label: 'Entregar Hoy', value: 'same' },
      { label: 'Programado', value: 'after' },
    ],
  });
  const mapRef = useRef<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    _handleMarkers();
  }, []);

  const _handleMarkers = async () => {

    if (moment(state.date_from).isAfter(moment(state.date_to))) {
      toast.error("La fecha desde no puede ser mayor a la fecha hasta");
      return;
    }
    if (moment(state.date_to).diff(moment(state.date_from), "days") > 30) {
      toast.error("El rango de fechas no puede ser mayor a 30 días");
      return;
    }
    let ordersWithDateStatus: any = [];

    dispatch(LOADING_ON());
    try {
      const targetStates: any = ['programmed', 'on_route'];
      let orders: any = [];

      for (const status of targetStates) {
        const response = await Orders.list({ status, date_from: moment(state.date_from).format('YYYY-MM-DD'), date_to: moment(state.date_to).format('YYYY-MM-DD') });
        response.data = response.data?.map((item: any) => {
          const [, order_status] = item.GS1PK?.split('-');
          item.status = order_status?.toLowerCase();
          return item;
        });
        orders = [...orders, ...response.data]
      }

      const positionsMarker: any = [];

      ordersWithDateStatus = orders.map((order: any) => {
        const delivery_date = order.info?.reservation?.schedule_at ? moment(order.info?.reservation?.schedule_at) : moment(order.created_at).add(1, 'days');
        const now = moment();
        order.info.direction.latitude = Number(order.info.direction.latitude.toFixed(7));
        order.info.direction.longitude = Number(order.info.direction.longitude.toFixed(7));

        const existentMarker = positionsMarker.find((item: any) => item.latitude === order.info.direction?.latitude && item.longitude === order.info.direction?.longitude);

        if (existentMarker) {
          let randomPosition = Math.floor(Math.random() * 8) + 9;
          if (randomPosition > 5) {
            order.info.direction.latitude = order.info.direction.latitude - Number('0.000' + randomPosition + '000');
          } else {
            order.info.direction.latitude = order.info.direction.latitude + Number('0.000' + randomPosition + '000');
          }
          randomPosition = Math.floor(Math.random() * 8) + 9;
          if (randomPosition > 5) {
            order.info.direction.longitude = order.info.direction.longitude - Number('0.000' + randomPosition + '000');
          } else {
            order.info.direction.longitude = order.info.direction.longitude + Number('0.000' + randomPosition + '000');
          }
        }
        positionsMarker.push({
          latitude: order.info.direction.latitude,
          longitude: order.info.direction.longitude,
        });

        let posibleStatus: any = {
          after: delivery_date.isAfter(now, 'day'),
          same: delivery_date.isSame(now, 'day'),
          before: delivery_date.isBefore(now, 'day'),
        };
        const status: any = Object.keys(posibleStatus).find((key: any) => posibleStatus[key] === true);
        order.delivery_status = status;
        order.delivery_day = delivery_date.format('DD/MM/YYYY HH:mm');
        return order;
      });
    } catch (e: any) {
      toast.error(e.message);
    }
    dispatch(LOADING_OFF());

    const loader: any = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : '',
      version: 'weekly',
      libraries: ['places'],
      ...{},
    });

    loader.load().then((google: any) => {
      let location: any = {};
      if (ordersWithDateStatus.length > 0) {
        location = { lat: ordersWithDateStatus[0].info?.direction?.latitude, lng: ordersWithDateStatus[0].info?.direction?.longitude };
      } else {
        location = { lat: -33.46526289530133, lng: -70.6861429404635 };
      }

      const map = new google.maps.Map(mapRef.current, {
        center: location,
        zoom: 10,
      });

      if (ordersWithDateStatus.length > 0) {
        const markers: any = ordersWithDateStatus.map((item: any) => _handleAddMarker(google, map, item));
        setState({ ...state, orders: ordersWithDateStatus, map, google, markers, filter_modal: false, selected_status: 'all' });
      } else {
        setState({ ...state, orders: ordersWithDateStatus, map, google, markers: [], filter_modal: false, selected_status: 'all' });
      }
    });
  };

  const _handleAddMarker = (google: any, map: any, item: any) => {
    const icon = item.delivery_status === 'same' ? Images.MarkerHouseYellow.src : item.delivery_status === 'before' ? Images.MarkerHouseRed.src : Images.MarkerHouseBlue.src;
    const marker = new google.maps.Marker({
      map: map,
      position: { lat: item.info?.direction.latitude, lng: item.info?.direction.longitude },
      icon: icon,
    });
    google.maps.event.addListener(marker, 'click', async () => {
      try {
        dispatch(LOADING_ON());
        const orderDetail: any = await Orders.detailsOrden({ PK: item.PK, SK: item.SK });

        let total: any = orderDetail.data.amount + orderDetail.data.amount_shipping;

        if (orderDetail.data?.complement?.length > 0) {
          total -= orderDetail.data.complement[0].amount_diff;
          orderDetail.data.is_complemented = true;
        }

        const [, order_status] = orderDetail.data.GS1PK?.split('-');
        orderDetail.data.status = order_status?.toLowerCase();
        orderDetail.data.total_without_discount = total;

        const [paymentPK, _] = item.PK.split('-')
        const paymentDetails: any = await Orders.paymentDetails(paymentPK);

        orderDetail.data.paymentData = paymentDetails.data;

        orderDetail.data?.items.sort((a: any, b: any) => {
          if (a.info?.type_item > b.info?.type_item) return 1;
          if (a.info?.type_item < b.info?.type_item) return -1;
          return 0;
        });

        setState((prevState: any) => {
          return { ...prevState, modal: { open: true }, order: { ...orderDetail.data, delivery_day: item.delivery_day } };
        });
      } catch (e: any) {
        toast.error('No se puedo recuperar los datos de este pedido');
        dispatch(LOADING_OFF());
      }
      dispatch(LOADING_OFF());
    });

    return marker;
  };

  const _handleClearMaps = () => {
    const { markers } = state;
    markers.forEach((item: any) => item.setMap(null));
  };

  const _handleCloseModal = () => setState({ ...state, filter_modal: false, modal: { open: false } });

  const _handleFilterOrders = (payload: any) => {
    const { google, map, orders } = state;
    _handleClearMaps();
    let markers: any = [];
    if (payload === 'all') {
      const filterOrders = orders.filter((order: any) => order.status === 'programmed' || order.status === 'on_route' || order.status === 'rescheduled');
      markers = filterOrders.map((order: any) => _handleAddMarker(google, map, order));
    } else {
      const filterOrders = orders.filter((order: any) => (order.status === 'programmed' || order.status === 'on_route' || order.status === 'rescheduled') && order.delivery_status === payload);
      markers = filterOrders.map((order: any) => _handleAddMarker(google, map, order));
    }
    setState({ ...state, selected_status: payload, markers });
  };

  const _handleChange = async ({ target }: any) => {
    const { name, value } = target;
    state[name] = value;
    setState({ ...state });
  };

  return (
    <div className="container-fluid">
      <ConfirmationModal handleClose={_handleCloseModal} handleConfirm={_handleCloseModal} hideFooter open={state.modal.open} title="">
        <MapsCard state={state} />
      </ConfirmationModal>

      <ConfirmationModal open={state.filter_modal} handleConfirm={_handleMarkers} handleClose={_handleCloseModal}>
        <div className="d-flex flex-wrap w-100">
          <div className="col-12 mb-2">
            <span className="size-08 ms-1 bold-300">Desde</span>
            <DatePicker
              format="dd/MM/yyyy"
              placeholder="Informe la fecha"
              style={{ width: '100%' }}
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              value={state.date_from}
              onChange={(value: any) => _handleChange({ target: { value, name: 'date_from' } })}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: 'Hoy',
                yesterday: 'Ayer',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos'
              }}
            />
          </div>
          <div className="col-12 mb-2">
            <span className="size-08 ms-1 bold-300">Hasta</span>
            <DatePicker
              format="dd/MM/yyyy"
              placeholder="Informe la fecha"
              style={{ width: '100%' }}
              classPrefix="copec"
              className="rs-copec-cleanable"
              cleanable={false}
              value={state.date_to}
              onChange={(value) => _handleChange({ target: { value, name: 'date_to' } })}
              locale={{
                sunday: 'Dom',
                monday: 'Lun',
                tuesday: 'Mar',
                wednesday: 'Mie',
                thursday: 'Jue',
                friday: 'Vie',
                saturday: 'Sab',
                ok: 'OK',
                today: 'Hoy',
                yesterday: 'Ayer',
                hours: 'Horas',
                minutes: 'Minutos',
                seconds: 'Segundos'
              }}
            />
          </div>
        </div>
      </ConfirmationModal>


      <div className="row mb-2 align-items-center">
        <div className="col-10">
          <SelectPicker
            data={state.status}
            value={state.selected_status}
            onChange={_handleFilterOrders}
            className='w-100'
            cleanable={false}
            locale={{
              emptyMessage: 'Vacio',
              searchPlaceholder: 'Buscar',
              noResultsText: 'Ningún resultado encontrado',
              placeholder: 'Estados',
            }}
          />
        </div>
        <div className="col-2">
          <button className='btn border-bottom border-3 rounded' onClick={() => setState({ ...state, filter_modal: true })}>
            <FaFilter />
          </button>
        </div>
      </div>
      <div className="row">
        <div style={{ width: '100%', height: '70vh' }} ref={mapRef} />
      </div>
    </div>
  );
};
export default OrderMap;
