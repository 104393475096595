import { Button, Modal } from 'rsuite';

const ConfirmationModal = (props: any) => {
  const { handleClose, open, handleConfirm, hideFooter, children, title, type, size } = props;

  const Warning = () => <i className="bi bi-exclamation-circle-fill" style={{ color: 'orange', fontSize: '30px' }}></i>;
  const Danger = () => <i className="bi bi-x-circle-fill size-15" style={{ color: 'red', fontSize: '30px' }}></i>;
  const Success = () => <i className="bi bi-check-circle-fill" style={{ color: 'green', fontSize: '30px' }}></i>;

  const Types: any = {
    undefined: '',
    success: <Success />,
    danger: <Danger />,
    warning: <Warning />,
  };

  const _handleTranslatetext = () => {
    const closeElements: any = document.querySelectorAll('[title=Close]');
    const clearElements: any = document.querySelectorAll('[title=Clear]');
    Array.from(closeElements).forEach((item: any) => {
      item.setAttribute('title', 'Cerrar');
    });
    Array.from(clearElements).forEach((item: any) => {
      item.setAttribute('title', 'Limpiar');
    });
  };

  return (
    <Modal open={open} onClose={handleClose} size={size || 'auto'} full={false} className="px-3" onEntered={_handleTranslatetext}>
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex align-items-center px-3">
            {Types[type]}
            <span className={`${type ? 'ms-4' : ''} bold color-grey`}>{title}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {!hideFooter && (
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            <span className="color-grey">Cancelar</span>
          </Button>
          <Button onClick={handleConfirm} appearance="primary">
            <span className="color-white">Confirmar</span>
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default ConfirmationModal;
