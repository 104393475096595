import { getSession } from '../helpers/session';
import store from '../redux/store';
import instance from './api_account';
class OptionsService {
  _setHeaders = () => {
    const state: any = store.getState();
    const { session } = state;

    const oidcSession = getSession();

    if (oidcSession) {
      instance.defaults.headers.common['user'] = session.profile?.PK;
      instance.defaults.headers.common['usersk'] = session.profile?.SK;
      instance.defaults.headers.common['Authorization'] = `Bearer ${oidcSession?.access_token}`;
    }
  };

  getOrderState = async () => {
    this._setHeaders();
    try {
      const response = await instance.get('/account/order/states');
      const unusedStates: any = ['new', 'aborted'];
      response.data.data = response.data.data.filter((item: any) => !unusedStates.find((state: any) => state === item.value?.toLowerCase()));
      return response.data;
    } catch (e: any) {
      return Promise.reject(e);
    }
  };
}

const Options = new OptionsService();
export default Options;
