import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ProfileImage from '../ProfileImage';
import WhisperComponent from '../WhisperComponent';
import './component.menu.scss';
import Images from '../../assets/images';
import axios from 'axios';

const Menu = ({ clearAuth }: any) => {
  const [state, setState] = useState({
    menu: false,
  });

  const { session } = useSelector((state: any) => state);
  const navigate = useNavigate();

  const _toggleMenu = () => setState({ ...state, menu: !state.menu });

  const _handleSignOut = () => {
    clearAuth();
  };

  const _handleNavigate = (target: any) => {
    _toggleMenu();
    navigate(target);
  };

  return (
    <div className="row position-relative">
      <div className="col-12 text-end">
        {/* <button className="btn bold" onClick={_toggleMenu}>
                    <i className="bi bi-bell color-grey" style={{ fontSize: '20px' }} />
                </button> */}
        <button className="btn bold color-grey" onClick={_toggleMenu}>
          <i className="bi bi-list" style={{ fontSize: '25px' }} />
        </button>
      </div>

      <div className="component-side-menu" style={{ overflow: "auto", transform: `translateX(${state.menu ? '0%' : '-100%'})` }}>
        <div className="component-side-menu__container">
          <button className="btn position-absolute" style={{ top: 0, right: '5px' }} onClick={_toggleMenu}>
            <i className="bi bi-x" style={{ fontSize: '35px' }} />
          </button>

          <div className="row pt-5">
            <div className="col-12 d-flex align-items-center flex-column px-5">
              <div className="col-12 text-center my-3">
                <img src={Images.Logo.src} alt={Images.Logo.alt} style={{ width: '180px' }} />
              </div>
              <ProfileImage _string={`${session?.profile?.name} ${session?.profile?.family_name}`} />
              <WhisperComponent text={session?.profile?.name}>
                <span className="bold mt-3 text-center size-08">{`${session?.profile?.name}`}</span>
              </WhisperComponent>
            </div>
          </div>

          <div className="row mx-3 mt-4 bg-white border-default rounded-5 py-2 bold-300">
            <div className="col-12 mt-1 border-bottom-default ">
              <button className="btn w-100 d-flex align-items-center" onClick={() => _handleNavigate('/')}>
                <span className="btn-default rounded-10 px-2 py-1 color-white me-3">
                  <i className="bi bi-house"></i>
                </span>
                Home
              </button>
            </div>
            <div className="col-12 mt-1 border-bottom-default" onClick={() => _handleNavigate('/router')}>
              <button className="btn w-100 d-flex align-items-center">
                <span className="btn-default rounded-10 px-2 py-1 color-white me-3">
                  <i className="bi bi-geo-alt"></i>
                </span>
                Enrutador
              </button>
            </div>
            <div className="col-12 mt-1 border-bottom-default" onClick={() => _handleNavigate('/orders/map')}>
              <button className="btn w-100 d-flex align-items-center">
                <span className="btn-default rounded-10 px-2 py-1 color-white me-3">
                  <i className="bi bi-map"></i>
                </span>
                Mapa
              </button>
            </div>
            <div className="col-12 mt-1 border-bottom-default">
              <a className="btn w-100 d-flex color-grey align-items-center" href={process.env.REACT_APP_PROTOCOL_LAVADO} target='_blank' download={`protocolo_lavado.pdf`} style={{textDecoration: "none"}}>
                <span className="btn-default rounded-10 px-2 py-1 color-white me-3">
                  <i className="bi bi-file-earmark-pdf"></i>
                </span>
                Protocolo Lavado
              </a>
            </div>
            <div className="col-12 mt-1 border-bottom-default">
              <a className="btn w-100 color-grey d-flex align-items-center" href={process.env.REACT_APP_PROTOCOL_LUB} target='_blank' download={`protocolo_lub.pdf`} style={{textDecoration: "none"}}>
                <span className="btn-default rounded-10 px-2 py-1 color-white me-3">
                  <i className="bi bi-file-earmark-pdf"></i>
                </span>
                Protocolo Cambio Aceite
              </a>
            </div>

          </div>

          <div className="row mx-3 mt-4 bg-white border-default rounded-5 py-2 bold-300">
            <div className="col-12 mt-1 color-black">
              <button className="btn w-100 d-flex align-items-center size-09 bold-300" onClick={_handleSignOut}>
                <span className="btn-grey rounded-10 px-2 py-1 me-3">
                  <i className="bi bi-box-arrow-left"></i>
                </span>
                Cerrar sesión
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Menu;
