/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { getSession } from "../../helpers/session";
import { useDispatch } from "react-redux";
import { signout } from "../../redux/actions/session";

const Logout = ({ clearAuth, authorize }: any) => {
    const dispatch:any = useDispatch()
    useEffect(() => {
        _isAuthenticated();
    }, []);

    const _isAuthenticated = async () => {
        const session: any = getSession();
        dispatch(signout());
        if (session) {
            clearAuth();
            return;
        }

        authorize();
    }


    return (
        <div className="container-fluid" style={{ height: '100vh' }}>
            <div className="row justify-content-center align-items-center h-100">
                <div className="col-12 text-center">
                    <div className="fw-bold mb-3 text-secondary ">Finalizando sesion...</div>
                </div>
            </div>
        </div>
    );
}

export default Logout;